<template>
	<div class="modal fade" id="propuesta" data-backdrop="static" role="dialog" aria-labelledby="propuestaLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
			<form ref="formPropuesta" v-on:submit.prevent="sendPropuesta()" class="needs-validation was-validated w-100" novalidate>
			<div class="modal-content">
				<div class="modal-header bg-primary text-white align-items-center">
					<h3 class="modal-title text-left w-100">Oferta</h3>
					<button type="button" class="btn btn-link" data-dismiss="modal" aria-label="Close" :disabled="loadingPropuesta">
						<img src="@/assets/image/close_white.webp" width="20" height="20">
					</button>
				</div>
				<div class="modal-body">
					<p class="text-center">¿Estás seguro que deseas realizar una oferta?</p>
					<div class="d-flex align-items-center" v-if="false">
						<div class="font-weight-bold mr-2">Q</div>
						<div class="w-100">
							<input type="text" v-model="form.montoConfirmacion" class="form-control bg-light" placeholder="Confirmar monto">
						</div>
					</div>
					<Currency type="text" v-model="form.montoConfirmacion" class="form-control bg-light" placeholder="Confirmar monto"/>
					<div class="text-danger" v-if="errorMontoConfirmacion">
						{{errorMontoConfirmacion}}
					</div>
			      </div>
		    	<div class="modal-footer text-right">
		    		<button type="submit" class="btn btn-success" :disabled="loadingPropuesta || !validateForm">
		    			<BtnLoading v-if="loadingPropuesta" />
						<span v-else>
							Ofertar
						</span>
		    		</button>
		    		<button class="btn btn-link" data-dismiss="modal" :disabled="loadingPropuesta">
		        		Cancelar
		    		</button>
		    	</div>
		  	</div>
		  	</form>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const Currency = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			BtnLoading,
			Currency
		},
		data(){
		  return {
		  	loadingPropuesta: false,
		  	validateForm: false,
		  	form: {
		  		montoConfirmacion: 0,
		  	},
		  	errorMontoConfirmacion: ''
		  }
		},
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				vm.toIndex()
			})
			this.form.montoConfirmacion = this.vehiculo.precio || 0
		},
		methods: {
			sendPropuesta(){
				this.errorMontoConfirmacion = ''
				if (this.form.montoConfirmacion<=0) {
		        	this.errorMontoConfirmacion = 'El monto debe ser mayor a 0'
		        	return
		        }
		        this.checkValidity()
				if (!this.validateForm) {
					return
				}
				var params = {
					monto: this.form.montoConfirmacion,
		            idVehiculo: this.id,
		            idUsuario: this.user.idusuario,
				}
				this.loadingPropuesta = true
				this.$store.dispatch('vehiculoOferta', params).then(response => {
					window.toastr.info("Oferta envíada exitosamente", "Éxito")
					this.form.montoConfirmacion = 0
					window.$(this.$el).modal('hide')

					this.$store.dispatch('getVehiculo',{
						idVehiculo: this.id,
						idUsuarioVehiculo: this.user.idusuario
					})

					return response
				}).catch(error =>{
					window.toastr.error('Error al envíar propuesta', 'Error')
					return error
				}).then(() => {
					this.loadingPropuesta = false
				})
			},
			mailsSendProposal(params){
				this.$store.dispatch('mailsSendProposal', {
					nomusuario: this.propuesta.nombreprs,
	                apellido: this.propuesta.apellidoprs,
	                mail: this.propuesta.idusuarioprs,
	                nombrePrenda: this.propuesta.nombre,
	                idpropuesta: this.propuesta.idpropuesta,
	                fotoPrenda: this.propuesta.ruta,
	                nomusuarioInv: this.propuesta.nombreinv,
	                apellidoInv: this.propuesta.apellidoinv,
	                montoOfertado: params.monto,
	                tasa: params.tasa,
	                tasaPeriodo: this.propuesta.tasaPeriodo,
	                tiempoPeriodo: this.propuesta.tiempoPeriodo,
	                tiempopago: params.tiempopago,
	                fechaAprobado: Math.round(new Date().getTime()/1000.0)
				})
			},
			sendPush(){
				this.$store.dispatch('sendPush', {
					idusuario : this.propuesta.idusuarioprs, 
		        	title: '¡Tienes una nueva propuesta!', 
		        	message: 'Han realizado una propuesta para ' + this.propuesta.nombre
				})
			},
			checkValidity(){
				if (!this.$refs.formPropuesta){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.formPropuesta.checkValidity()
				}
			},
			toIndex(){
				this.$router.push({name: 'vehiculos.show', params:{id: this.id}})
			},
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			vehiculo(){
				return this.$store.getters.infoVehiculo || {}
			},
		},
		watch:{
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
			'vehiculo':{
				handler(val){
					this.form.montoConfirmacion = val.precio || 0
					return val
				},
				deep: true
			}
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>