<template>
    <form ref="userForm" v-on:submit.prevent="registerUser()" class="needs-validation was-validated" novalidate>
        <div class="row">
            <div class="col-lg-2 text-center">
                <div :class="{'rounded mb-3 d-flex justify-content-center align-items-end':true, 'bg-primary px-3 pt-3':!form.foto}">
                    <img v-lazy="$filters.url(form.foto)" class="img-fluid" alt="Foto perfil" title="Foto perfil" v-if="form.foto">
                    <img v-lazy="imageUsuarios" class="img-fluid" style="height: 130px;" alt="Foto perfil" title="Foto perfil" v-else>
                </div>
                <Upload class="btn btn-secondary rounded" name="foto" v-on:fileChange="uploadFileRegister($event, 'foto')">
                    <img v-lazy="imageCruz" width="15" height="15" alt="Seleccionar foto">
                    <small class="font-weight-bold">Seleccionar foto</small>
                </Upload>
                <div class="text-primary font-weight-bold">
                    (Tu foto no será visible al público)
                </div>
            </div>
            <div class="col-lg-5">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <input id="email" type="email" class="form-control" v-model="form.mail" name="mail" minlength="5" maxlength="100" placeholder="Correo" required>
                    </div>
                    <div class="col-md-12 mb-2">
                        <input id="password" type="password" class="form-control" v-model="form.password" name="password" minlength="8" placeholder="Contraseña" required autocomplete="on">
                    </div>
                    <div class="col-md-12 mb-2" v-if="false">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" name="perfil" id="inversionista" v-model="form.inversionista" value="true">
                                    <label class="custom-control-label" for="inversionista">
                                        <small>Perfil inversionista</small>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" name="perfil" id="credito" v-model="form.inversionista" value="false">
                                    <label class="custom-control-label" for="credito">
                                        <small>Perfil solicitante crédito</small>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-12 mb-2">
                        <input id="alias" type="text" class="form-control" v-model="form.alias" name="alias" placeholder="Alias" required>
                    </div>
                    <div class="col-md-12 mb-2">
                        <input id="nombre" type="text" class="form-control" v-model="form.nombre" name="nombre" placeholder="Nombre" required>
                    </div>
                    <div class="col-md-12 mb-2">
                        <input id="apellido" type="text" class="form-control" v-model="form.apellido" name="apellido" placeholder="Apellido" required>
                    </div>
                    <div class="col-md-12 mb-2">
                        <input id="dpi" type="text" class="form-control" v-model="form.dpi" name="dpi" placeholder="DPI" required>
                    </div>
                    <div class="col-md-12 mb-2">
                        <input id="telefono" type="tel" class="form-control" v-model="form.telefono" placeholder="Teléfono">
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="row align-items-end">
                    <div class="col-md-4 mb-3">
                        <small>
                        Foto personal tipo pasaporte/DPI <strong>(Tu foto no será visible al público)</strong>
                        </small>
                    </div>
                    <div class="col-md-4 mb-3">
                        <Upload :class="{'mb-3 text-center w-100 cursor-pointer border-dashed rounded-sm overflow-hidden':true, 'bg-white p-5':!form.selfie}" name="selfie" v-on:fileChange="uploadFileRegister($event, 'selfie')">
                            <img v-lazy="$filters.url(form.selfie)" class="img-fluid mx-auto" alt="Foto personal tipo pasaporte / DPI" title="Agregar foto" v-if="form.selfie">
                            <img v-lazy="imageCruz" class="img-fluid mx-auto" alt="Foto personal tipo pasaporte / DPI" title="Agregar foto" v-else>
                        </Upload>
                    </div>
                </div>
                <div class="row align-items-end">
                    <div class="col-md-4 mb-3">
                        <small>
                        Foto DPI (Ambos lados) <strong>(Tu foto no será visible al público)</strong>
                        </small>
                    </div>
                    <div class="col-md-4 mb-3">
                        <Upload :class="{'mb-3 text-center w-100 cursor-pointer border-dashed rounded-sm overflow-hidden':true, 'bg-white p-5':!form.dpifront}" name="dpifront" v-on:fileChange="uploadFileRegister($event, 'dpifront')">
                            <img v-lazy="$filters.url(form.dpifront)" class="img-fluid mx-auto" alt="Foto de DPI delantera" title="Agregar foto de DPI" v-if="form.dpifront">
                            <img v-lazy="imageCruz" class="img-fluid mx-auto" alt="Foto de DPI delantera" title="Agregar foto de DPI" v-else>
                        </Upload>
                    </div>
                    <div class="col-md-4 mb-3">
                        <Upload :class="{'mb-3 text-center w-100 cursor-pointer border-dashed rounded-sm overflow-hidden':true, 'bg-white p-5':!form.dpiback}" name="dpiback" v-on:fileChange="uploadFileRegister($event, 'dpiback')">
                            <img v-lazy="$filters.url(form.dpiback)" class="img-fluid mx-auto" alt="Agregar foto de DPI trasera" title="Agregar foto de DPI posterior" v-if="form.dpiback">
                            <img v-lazy="imageCruz" class="img-fluid mx-auto" alt="Agregar foto de DPI trasera" title="Agregar foto de DPI posterior" v-else>
                        </Upload>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="tos" id="terminos" v-model="form.tos" required>
                            <label class="custom-control-label" for="terminos">
                                <small>He leído y acepto los términos y condiciones.</small>
                            </label>
                        </div>
                    </div>
                    <div class="text-right">
                        <button type="submit" class="btn btn-success rounded font-weight-bold" :disabled="loadingRegister || !validateForm">
                            <BtnLoading v-if="loadingRegister" />
                            <span v-else>
                                Registrarse
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	var md5 = require('md5')
	export default {
        props: [
            'redirect',
            'exito'
        ],
		components: {
			BtnLoading,
			Upload
		},
		data(){
			return {
				validateForm: false,
				loadingRegister: false,

				form:{
					mail: '',
					password: '',
					//inversionista: '',
					alias: '',
					nombre: '',
					apellido: '',
					dpi: '',
					telefono: '',
					tos: '',
					solicitud: 0,
					selfie: '',
					dpifront: '',
					dpiback: '',
					foto: ''
				},
				loadingFile:false,

				imageCruz: require('@/assets/image/cruz.webp'),
				imageUsuarios: require('@/assets/image/usuarios.webp'),
			}
		},
		methods: {
			registerUser(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingRegister = true
				this.$store.dispatch('register', {
					mail: this.form.mail,
					password: this.passwordMD5,
					alias: this.form.alias,
					nombre: this.form.nombre,
					apellido: this.form.apellido,
					dpi: this.form.dpi,
					telefono: this.form.telefono,
					tos: this.form.tos,
					selfie: this.form.selfie,
					dpifront: this.form.dpifront,
					dpiback: this.form.dpiback,
					foto: this.form.foto,
				}).then(response => {
					this.$store.dispatch('registroExitoso', this.form)
					window.toastr.info("Registro exitoso, inicia sesión con tus credenciales", "Éxito")
                    if(this.redirect == true){
					    this.$router.push({name: 'login'})
                    }
                    this.$emit('exito', true)
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar usuario', 'Error')
					}
					return error
				}).then(() => {
					this.loadingRegister = false
				})
			},
			uploadFileRegister(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				formData.append('images', file)
		        formData.append('modules', 'usuarios')
				this.$store.dispatch('uploadFile', formData).then(response => {
					this.form[tipo] = response.data[0]
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
		},
		computed: {
			passwordMD5(){
				if (this.form.password!='') {
					return md5(this.form.password)
				}
				return ''
			},
		},
		watch:{
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>