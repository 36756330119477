<template>
	<div class="card shadow">
		<div class="card-body">
			<form ref="inspeccionForm" v-on:submit.prevent="validateInspeccion()" class="needs-validation was-validated" novalidate>
				<div class="row">
					<div class="col-md-6 mb-2">
						<h2 class="font-weight-bold">INSPECCIÓN DE VEHÍCULO</h2>
					</div>
					<div class="col-md-6 mb-1">
						<div class="d-flex">
							<div class="border rounded px-3 py-2 mr-3">
								{{$filters.date(vehiculo.fechaInspeccion)}}
							</div>
							<div class="border rounded p-2 w-50">
								No. {{vehiculo.idVehiculo}}
							</div>
						</div>
					</div>

					<label class="ml-3" v-if="validateShow">
						<span v-if="user.prf == 3">
							Los campos con <span class="font-weight-bold">**</span> están marcados como datos privados (solo los administradores y el vendedor podrán verlos).
						</span>
						<span v-else>
							Los campos con <span class="font-weight-bold">**</span> están marcados como datos privados (solo tú podrás verlos).
						</span>
					</label>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-3 mb-2">
								<div class="d-flex">
								 	<span class="font-weight-bold mr-2">
								 		Marca:
								 	</span>
									<span class="border-bottom w-100 text-center text-truncate">
										{{vehiculo.marca}}
									</span>
								</div>
							</div>
							<div class="col-md-3 mb-2">
								<div class="d-flex">
								 	<span class="font-weight-bold mr-2">
								 		Año:
								 	</span>
									<span class="border-bottom w-100 text-center text-truncate">
										{{vehiculo.ano}}
									</span>
								</div>
							</div>
							<div class="col-md-6 mb-2" v-if="validateShow">
								<div class="d-flex align-items-center">
									<label class="font-weight-bold mr-2 mb-0">**Chasis:</label>
									<input type="text" name="chasis" v-model="inspeccion.chasis" maxlength="50" class="form-control border" placeholder="Ingrese chasis" required :disabled="disabledDF">
								</div>
							</div>
							<div class="col-md-6 mb-2">
								<div class="d-flex">
								 	<span class="font-weight-bold mr-2">
								 		Línea:
								 	</span>
									<span class="border-bottom w-100 text-center text-truncate">
										{{vehiculo.modelo}}
									</span>
								</div>
							</div>
							<div class="col-md-6 mb-2">
								<div class="d-flex">
								 	<span class="font-weight-bold mr-2">
								 		Recorrido:
								 	</span>
									<span class="border-bottom w-100 text-center text-truncate">
										{{$filters.currency(vehiculo.kilometraje, false)}} km
									</span>
								</div>
							</div>
						</div>
						<div class="row align-items-center">
							<div class="col-md-6 mb-2" v-if="validateShow">
								<div class="d-flex align-items-center">
									<label class="font-weight-bold mr-2 mb-0">**Placa:</label>
									<input type="text" name="placa" v-model="inspeccion.placa" maxlength="50" class="form-control border" placeholder="Ingrese placa" required :disabled="disabledDF">
								</div>
							</div>
							<div class="col-md-3 mb-2">
								<div class="d-flex">
								 	<span class="font-weight-bold mr-2">
								 		Motor:
								 	</span>
									<span class="border-bottom w-100 text-center text-truncate">
										{{vehiculo.motor}}
									</span>
								</div>
							</div>
							<div class="col-md-3 mb-2">
								<div class="d-flex">
								 	<span class="font-weight-bold mr-2">
								 		Color:
								 	</span>
									<span class="border-bottom w-100 text-center text-truncate">
										{{vehiculo.color}}
									</span>
								</div>
							</div>
							<div class="col-md-6 mb-2" v-if="validateShow">
								<div class="d-flex">
								 	<span class="font-weight-bold mr-2">
								 		**Avalúo:
								 	</span>
									<span class="border-bottom w-100 text-center text-truncate">
										**Valor comercial sugerido: <strong>{{$filters.currency(vehiculo.precio)}}</strong>
									</span>
								</div>
							</div>
							<div class="col-md-6 mb-2" v-if="validateShow">
								<div class="d-flex align-items-center">
								 	<span class="font-weight-bold mr-2 w-100">
								 		**Precio bancario:
								 	</span>
									<input type="text" name="precioBanco" v-model="inspeccion.precioBanco" class="form-control border" placeholder="Ingrese monto" required :disabled="disabledDF">
								</div>
							</div>
							<div class="col-md-4 mb-2">
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="agencia" name="origen" class="custom-control-input" :value="1" v-model="vehiculo.origen" disabled>
									<label class="custom-control-label" for="agencia">Agencia</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="rodado" name="origen" class="custom-control-input" :value="0" v-model="vehiculo.origen" disabled>
									<label class="custom-control-label" for="rodado">Rodado</label>
								</div>
							</div>
							<div class="col-md-4 mb-2">
								<div class="d-flex">
									<span class="font-weight-bold mr-2">
										Bolsas de aire:
									</span>
									<div>
										<div class="custom-control custom-radio custom-control-inline">
											<input type="radio" id="bolsasAireSi" name="bolsasAire" class="custom-control-input" v-model="inspeccion.bolsasAire" :value="1" :disabled="disabledDF" required>
											<label class="custom-control-label" for="bolsasAireSi">Si</label>
										</div>
										<div class="custom-control custom-radio custom-control-inline">
											<input type="radio" id="bolsasAireNo" name="bolsasAire" class="custom-control-input" v-model="inspeccion.bolsasAire" :value="0" :disabled="disabledDF" required>
											<label class="custom-control-label" for="bolsasAireNo">No</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<h3>
							<a href="#" v-on:click.prevent="ventanas.seguridad=!ventanas.seguridad">
								ELEMENTOS DE SEGURIDAD
							</a>
						</h3>
						<div class="row" v-show="ventanas.seguridad">
							<div class="col-md-7">
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Cinturones de seguridad delanteros - funcionando
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="cinturonSeguridadDelantero" v-model="inspeccion.cinturonSeguridadDelantero" :disabled="disabledDF">
										<label class="custom-control-label" for="cinturonSeguridadDelantero">
											<span v-if="false">
												{{inspeccion.cinturonSeguridadDelantero ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Cinturones de seguridad traseros - funcionando
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="cinturonSeguridadTraseros" v-model="inspeccion.cinturonSeguridadTraseros" :disabled="disabledDF">
										<label class="custom-control-label" for="cinturonSeguridadTraseros">
											<span v-if="false">
												{{inspeccion.cinturonSeguridadTraseros ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div :class="{'w-50':inspeccion.tapiceriaAsientos,'w-100':!inspeccion.tapiceriaAsientos}">
										Tapicería de Asientos
									</div>
									<div class="px-2 w-50" v-if="inspeccion.tapiceriaAsientos">
										<input type="text" name="tapiceriaAsientosTipo" v-model="inspeccion.tapiceriaAsientosTipo" class="form-control form-control-sm border" placeholder="Ingrese tipo" maxlength="50" required :disabled="disabledDF">
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="tapiceriaAsientos" v-model="inspeccion.tapiceriaAsientos" :disabled="disabledDF">
										<label class="custom-control-label" for="tapiceriaAsientos">
											<span v-if="false">
												{{inspeccion.tapiceriaAsientos ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Herramienta Extra
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="herramientaExtra" v-model="inspeccion.herramientaExtra" :disabled="disabledDF">
										<label class="custom-control-label" for="herramientaExtra">
											<span v-if="false">
												{{inspeccion.herramientaExtra ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Cables de Corriente
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="cableCorriente" v-model="inspeccion.cableCorriente" :disabled="disabledDF">
										<label class="custom-control-label" for="cableCorriente">
											<span v-if="false">
												{{inspeccion.cableCorriente ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Extintor
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="extintor" v-model="inspeccion.extintor" :disabled="disabledDF">
										<label class="custom-control-label" for="extintor">
											<span v-if="false">
												{{inspeccion.extintor ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Botiquín de primeros auxilios
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="botiquin" v-model="inspeccion.botiquin" :disabled="disabledDF">
										<label class="custom-control-label" for="botiquin">
											<span v-if="false">
												{{inspeccion.botiquin ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Tricket, Manivela
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="ticketManivela" v-model="inspeccion.ticketManivela" :disabled="disabledDF">
										<label class="custom-control-label" for="ticketManivela">
											<span v-if="false">
												{{inspeccion.ticketManivela ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Llave de Chuchos
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="llaveChuchos" v-model="inspeccion.llaveChuchos" :disabled="disabledDF">
										<label class="custom-control-label" for="llaveChuchos">
											<span v-if="false">
												{{inspeccion.llaveChuchos ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Triángulos reflectivos
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="triangulosReflectivos" v-model="inspeccion.triangulosReflectivos" :disabled="disabledDF">
										<label class="custom-control-label" for="triangulosReflectivos">
											<span v-if="false">
												{{inspeccion.triangulosReflectivos ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Cristales y Retrovisores
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="cristalesRetrovisores" v-model="inspeccion.cristalesRetrovisores" :disabled="disabledDF">
										<label class="custom-control-label" for="cristalesRetrovisores">
											<span v-if="false">
												{{inspeccion.cristalesRetrovisores ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Tarjeta de Circulación
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="tarjetaCirculacion" v-model="inspeccion.tarjetaCirculacion" :disabled="disabledDF">
										<label class="custom-control-label" for="tarjetaCirculacion">
											<span v-if="false">
												{{inspeccion.tarjetaCirculacion ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="border-bottom mb-1 pb-1">
									<div class="custom-control custom-radio custom-control-inline">
										<input type="radio" id="platos" name="platosTapones" class="custom-control-input" v-model="inspeccion.platosTapones" :value="1" required :disabled="disabledDF">
										<label class="custom-control-label" for="platos">Platos</label>
									</div>
									<div class="custom-control custom-radio custom-control-inline">
										<input type="radio" id="tapones" name="platosTapones" class="custom-control-input" v-model="inspeccion.platosTapones" :value="0" required :disabled="disabledDF">
										<label class="custom-control-label" for="tapones">Aros</label>
									</div>
								</div>
								<div class="d-flex border-bottom mb-1 pb-1 align-items-center">
									<div class="w-100">
										Antena
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="antena" v-model="inspeccion.antena" :disabled="disabledDF">
										<label class="custom-control-label" for="antena">
											<span v-if="false">
												{{inspeccion.antena ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
								<div class="d-flex mb-1 pb-1 align-items-center">
									<div class="w-100">
										Alfombras
									</div>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="alfombras" v-model="inspeccion.alfombras" :disabled="disabledDF">
										<label class="custom-control-label" for="alfombras">
											<span v-if="false">
												{{inspeccion.alfombras ? 'SI' : 'NO'}}
											</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-md-5">
								<div v-if="disabledDF">
									<div class="card">
										<div class="card-body">
											<img v-lazy="$filters.storage(inspeccion.foto)" class="img-fluid">
										</div>
									</div>
								</div>
								<div v-else>
									<label>Plano:</label>
									<Upload :class="{'mb-3 text-center w-100 cursor-pointer border-dashed rounded-sm overflow-hidden':true, 'bg-white p-5':!inspeccion.foto}" name="foto" v-on:fileChange="uploadFile($event)">
										<img v-lazy="$filters.storage(inspeccion.foto)" class="img-fluid mx-auto" alt="Insepccion plano" title="Agregar foto" v-if="inspeccion.foto">
										<img src="../../assets/image/cruz.webp" class="img-fluid mx-auto" alt="Insepccion plano" title="Agregar foto" v-else>
									</Upload>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<h3>
							<a href="#" v-on:click.prevent="ventanas.electrico=!ventanas.electrico">
								SISTEMA ELECTRICO
							</a>
						</h3>
						<div v-show="ventanas.electrico">
							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Estado de batería (Bueno 100% /Regular 50% / Malo 45%)
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="estadoBateria" v-model="inspeccion.estadoBateria" :disabled="disabledDF">
											<label class="custom-control-label" for="estadoBateria">
												<span v-if="false">
													{{inspeccion.estadoBateria ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="estadoBateriaObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.estadoBateriaObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Luces Delanteras y Traseras
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="lucesDelanterasTraseras" v-model="inspeccion.lucesDelanterasTraseras" :disabled="disabledDF">
											<label class="custom-control-label" for="lucesDelanterasTraseras">
												<span v-if="false">
													{{inspeccion.lucesDelanterasTraseras ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="lucesDelanterasTraserasObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.lucesDelanterasTraserasObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Luces Direccionales y Retroceso
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="lucesDireccionalesRetroceso" v-model="inspeccion.lucesDireccionalesRetroceso" :disabled="disabledDF">
											<label class="custom-control-label" for="lucesDireccionalesRetroceso">
												<span v-if="false">
													{{inspeccion.lucesDireccionalesRetroceso ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="lucesDireccionalesRetrocesoObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.lucesDireccionalesRetrocesoObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Limpia parabrisas
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="limpiaParabrisas" v-model="inspeccion.limpiaParabrisas" :disabled="disabledDF">
											<label class="custom-control-label" for="limpiaParabrisas">
												<span v-if="false">
													{{inspeccion.limpiaParabrisas ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="limpiaParabrisasObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.limpiaParabrisasObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Bocina Claxon
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="bocinaClaxon" v-model="inspeccion.bocinaClaxon" :disabled="disabledDF">
											<label class="custom-control-label" for="bocinaClaxon">
												<span v-if="false">
													{{inspeccion.bocinaClaxon ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="bocinaClaxonObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.bocinaClaxonObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Alternador y Motor de Arranque
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="alternadorMotorArranque" v-model="inspeccion.alternadorMotorArranque" :disabled="disabledDF">
											<label class="custom-control-label" for="alternadorMotorArranque">
												<span v-if="false">
													{{inspeccion.alternadorMotorArranque ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="alternadorMotorArranqueObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.alternadorMotorArranqueObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Tablero de Indicadores
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="tableroIndicaciones" v-model="inspeccion.tableroIndicaciones" :disabled="disabledDF">
											<label class="custom-control-label" for="tableroIndicaciones">
												<span v-if="false">
													{{inspeccion.tableroIndicaciones ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="tableroIndicacionesObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.tableroIndicacionesObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<h3>
							<a href="#" v-on:click.prevent="ventanas.pruebas=!ventanas.pruebas">
								PRUEBA DEL VEHICULO (Conducir)
							</a>
						</h3>
						<div v-show="ventanas.pruebas">
							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Estado de Motor
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="estadoMotor" v-model="inspeccion.estadoMotor" :disabled="disabledDF">
											<label class="custom-control-label" for="estadoMotor">
												<span v-if="false">
													{{inspeccion.estadoMotor ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="estadoMotorObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.estadoMotorObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Embrague  y Transmisión Manual
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="embragueTransmisionManual" v-model="inspeccion.embragueTransmisionManual" :disabled="disabledDF">
											<label class="custom-control-label" for="embragueTransmisionManual">
												<span v-if="false">
													{{inspeccion.embragueTransmisionManual ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="embragueTransmisionManualObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.embragueTransmisionManualObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Tracción 4x4
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="tranccion" v-model="inspeccion.tranccion" :disabled="disabledDF">
											<label class="custom-control-label" for="tranccion">
												<span v-if="false">
													{{inspeccion.tranccion ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="tranccionObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.tranccionObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Freno de Mano y Pie
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="frenoManoPie" v-model="inspeccion.frenoManoPie" :disabled="disabledDF">
											<label class="custom-control-label" for="frenoManoPie">
												<span v-if="false">
													{{inspeccion.frenoManoPie ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="frenoManoPieObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.frenoManoPieObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Alineación
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="alineacion" v-model="inspeccion.alineacion" :disabled="disabledDF">
											<label class="custom-control-label" for="alineacion">
												<span v-if="false">
													{{inspeccion.alineacion ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="alineacionObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.alineacionObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Balanceo de ruedas
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="balanceoRuedas" v-model="inspeccion.balanceoRuedas" :disabled="disabledDF">
											<label class="custom-control-label" for="balanceoRuedas">
												<span v-if="false">
													{{inspeccion.balanceoRuedas ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="balanceoRuedasObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.balanceoRuedasObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Aceite de motor %
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="aceiteMotor" v-model="inspeccion.aceiteMotor" :disabled="disabledDF">
											<label class="custom-control-label" for="aceiteMotor">
												<span v-if="false">
													{{inspeccion.aceiteMotor ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="aceiteMotorObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.aceiteMotorObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<h3>
							<a href="#" v-on:click.prevent="ventanas.condiciones=!ventanas.condiciones">
								CONDICIONES MECANICAS
							</a>
						</h3>
						<div v-show="ventanas.condiciones">
							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Vidrios y Espejos
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="vidriosEspejos" v-model="inspeccion.vidriosEspejos" :disabled="disabledDF">
											<label class="custom-control-label" for="vidriosEspejos">
												<span v-if="false">
													{{inspeccion.vidriosEspejos ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="vidriosEspejosObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.vidriosEspejosObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Cerraduras
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="cerraduras" v-model="inspeccion.cerraduras" :disabled="disabledDF">
											<label class="custom-control-label" for="cerraduras">
												<span v-if="false">
													{{inspeccion.cerraduras ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="cerradurasObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.cerradurasObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Sistema inyección y Bomba de gasolina
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="inyeccionBomba" v-model="inspeccion.inyeccionBomba" :disabled="disabledDF">
											<label class="custom-control-label" for="inyeccionBomba">
												<span v-if="false">
													{{inspeccion.inyeccionBomba ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="inyeccionBombaObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.inyeccionBombaObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Tanque de Combustible
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="tanqueCombustible" v-model="inspeccion.tanqueCombustible" :disabled="disabledDF">
											<label class="custom-control-label" for="tanqueCombustible">
												<span v-if="false">
													{{inspeccion.tanqueCombustible ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="tanqueCombustibleObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.tanqueCombustibleObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Bujías y Cables de Alta
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="bujiasCablesAlta" v-model="inspeccion.bujiasCablesAlta" :disabled="disabledDF">
											<label class="custom-control-label" for="bujiasCablesAlta">
												<span v-if="false">
													{{inspeccion.bujiasCablesAlta ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="bujiasCablesAltaObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.bujiasCablesAltaObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Diferencial Delantero
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="diferenciaDelantero" v-model="inspeccion.diferenciaDelantero" :disabled="disabledDF">
											<label class="custom-control-label" for="diferenciaDelantero">
												<span v-if="false">
													{{inspeccion.diferenciaDelantero ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="diferenciaDelanteroObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.diferenciaDelanteroObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Diferencial Trasero
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="diferenciaTrasero" v-model="inspeccion.diferenciaTrasero" :disabled="disabledDF">
											<label class="custom-control-label" for="diferenciaTrasero">
												<span v-if="false">
													{{inspeccion.diferenciaTrasero ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="diferenciaTraseroObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.diferenciaTraseroObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Caja de transfer
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="cajaTransfer" v-model="inspeccion.cajaTransfer" :disabled="disabledDF">
											<label class="custom-control-label" for="cajaTransfer">
												<span v-if="false">
													{{inspeccion.cajaTransfer ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="cajaTransferObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.cajaTransferObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Caja de cambios
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="cajaCambios" v-model="inspeccion.cajaCambios" :disabled="disabledDF">
											<label class="custom-control-label" for="cajaCambios">
												<span v-if="false">
													{{inspeccion.cajaCambios ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="cajaCambiosObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.cajaCambiosObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Dirección Hidráulica y Mangueras de Presión 
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="direccionHidraulica" v-model="inspeccion.direccionHidraulica" :disabled="disabledDF">
											<label class="custom-control-label" for="direccionHidraulica">
												<span v-if="false">
													{{inspeccion.direccionHidraulica ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="direccionHidraulicaObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.direccionHidraulicaObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Nivel de Fluidos
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="nivelFluidos" v-model="inspeccion.nivelFluidos" :disabled="disabledDF">
											<label class="custom-control-label" for="nivelFluidos">
												<span v-if="false">
													{{inspeccion.nivelFluidos ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="nivelFluidosObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.nivelFluidosObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Radiador y mangueras
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="radiadorMagueras" v-model="inspeccion.radiadorMagueras" :disabled="disabledDF">
											<label class="custom-control-label" for="radiadorMagueras">
												<span v-if="false">
													{{inspeccion.radiadorMagueras ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="radiadorMaguerasObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.radiadorMaguerasObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Correa de bomba de agua y alternador
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="correaBombaAgua" v-model="inspeccion.correaBombaAgua" :disabled="disabledDF">
											<label class="custom-control-label" for="correaBombaAgua">
												<span v-if="false">
													{{inspeccion.correaBombaAgua ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="correaBombaAguaObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.correaBombaAguaObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Suspensión y Amortiguación
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="suspensionAmortiguacion" v-model="inspeccion.suspensionAmortiguacion" :disabled="disabledDF">
											<label class="custom-control-label" for="suspensionAmortiguacion">
												<span v-if="false">
													{{inspeccion.suspensionAmortiguacion ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="suspensionAmortiguacionObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.suspensionAmortiguacionObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Sistema de Dirección
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="sistemaDireccion" v-model="inspeccion.sistemaDireccion" :disabled="disabledDF">
											<label class="custom-control-label" for="sistemaDireccion">
												<span v-if="false">
													{{inspeccion.sistemaDireccion ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="sistemaDireccionObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.sistemaDireccionObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center border-bottom mb-1 pb-1">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											Sistema de Escape
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="sistemaEscape" v-model="inspeccion.sistemaEscape" :disabled="disabledDF">
											<label class="custom-control-label" for="sistemaEscape">
												<span v-if="false">
													{{inspeccion.sistemaEscape ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="sistemaEscapeObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.sistemaEscapeObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>

							<div class="row align-items-center mb-1 pb-1" v-if="validateShow">
								<div class="col-md-7">
									<div class="d-flex align-items-center">
										<div class="w-100">
											**Chasis y Carrocería
										</div>
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="chasisCarroceria" v-model="inspeccion.chasisCarroceria" :disabled="disabledDF">
											<label class="custom-control-label" for="chasisCarroceria">
												<span v-if="false">
													{{inspeccion.chasisCarroceria ? 'SI' : 'NO'}}
												</span>
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-5 mt-md-0 mt-1">
									<input type="text" name="chasisCarroceriaObservaciones" placeholder="Observación" class="form-control form-control-sm border" v-model="inspeccion.chasisCarroceriaObservaciones" maxlength="50" :disabled="disabledDF">
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-5 mb-3">
								<div class="d-flex">
									<span class="mr-3">Condiciones de Llantas ({{inspeccion.porcLlantas}}%)</span>
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="condicionesLlantas" v-model="inspeccion.condicionesLlantas" disabled>
										<label class="custom-control-label" for="condicionesLlantas">
											<span>
												{{inspeccion.condicionesLlantas ? 'BIEN' : 'MAL'}}
											</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-md-7">
								<div class="d-flex justify-content-between align-items-center">
									<div class="border p-2 rounded">
										<div class="cap-bg border rounded overflow-hidden d-flex align-items-end mx-auto mb-3" style="height: 50px; width: 25px;">
											<div :class="{'w-100':true, 'bg-danger':inspeccion.llantaRepuesto<=40, 'bg-warning':inspeccion.llantaRepuesto>40 && inspeccion.llantaRepuesto<80, 'bg-success':inspeccion.llantaRepuesto>=80}" :style="{height: inspeccion.llantaRepuesto+'%'}"></div>
										</div>
										<div class="d-flex align-items-center">
											<input type="number" name="llantaRepuesto" v-model="inspeccion.llantaRepuesto" min="0" max="100" step="1" class="form-control form-control-sm w-75 border mr-1" :disabled="disabledDF">
											%
										</div>
									</div>
									<div class="ml-2 w-100">
										<div class="d-flex justify-content-between mb-2">
											<div class="border p-2 rounded ml-2">
												<div class="cap-bg border rounded overflow-hidden mx-auto mb-1" style="height: 25px; width: 50px;">
													<div :class="{'h-100':true, 'bg-danger':inspeccion.llanta1<=40, 'bg-warning':inspeccion.llanta1>40 && inspeccion.llanta1<80, 'bg-success':inspeccion.llanta1>=80}" :style="{width: inspeccion.llanta1+'%'}"></div>
												</div>
												<div class="d-flex align-items-center">
													<input type="number" name="llanta1" v-model="inspeccion.llanta1" min="0" max="100" step="1" class="form-control form-control-sm w-75 border mr-1" :disabled="disabledDF">
													%
												</div>
											</div>
											<div class="border p-2 rounded">
												<div class="cap-bg border rounded overflow-hidden mx-auto mb-1" style="height: 25px; width: 50px;">
													<div :class="{'h-100':true, 'bg-danger':inspeccion.llanta2<=40, 'bg-warning':inspeccion.llanta2>40 && inspeccion.llanta2<80, 'bg-success':inspeccion.llanta2>=80}" :style="{width: inspeccion.llanta2+'%'}"></div>
												</div>
												<div class="d-flex align-items-center">
													<input type="number" name="llanta2" v-model="inspeccion.llanta2" min="0" max="100" step="1" class="form-control form-control-sm w-75 border mr-1" :disabled="disabledDF">
													%
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-between">
											<span class="material-icons">arrow_right_alt</span>
											<span class="material-icons">arrow_right_alt</span>
											<span class="material-icons">arrow_right_alt</span>
										</div>
										<div class="d-flex justify-content-between">
											<div class="border p-2 rounded ml-2">
												<div class="cap-bg border rounded overflow-hidden mx-auto mb-1" style="height: 25px; width: 50px;">
													<div :class="{'h-100':true, 'bg-danger':inspeccion.llanta3<=40, 'bg-warning':inspeccion.llanta3>40 && inspeccion.llanta3<80, 'bg-success':inspeccion.llanta3>=80}" :style="{width: inspeccion.llanta3+'%'}"></div>
												</div>
												<div class="d-flex align-items-center">
													<input type="number" name="llanta3" v-model="inspeccion.llanta3" min="0" max="100" step="1" class="form-control form-control-sm w-75 border mr-1" :disabled="disabledDF">
													%
												</div>
											</div>
											<div class="border p-2 rounded">
												<div class="cap-bg border rounded overflow-hidden mx-auto mb-1" style="height: 25px; width: 50px;">
													<div :class="{'h-100':true, 'bg-danger':inspeccion.llanta4<=40, 'bg-warning':inspeccion.llanta4>40 && inspeccion.llanta4<80, 'bg-success':inspeccion.llanta4>=80}" :style="{width: inspeccion.llanta4+'%'}"></div>
												</div>
												<div class="d-flex align-items-center">
													<input type="number" name="llanta4" v-model="inspeccion.llanta4" min="0" max="100" step="1" class="form-control form-control-sm w-75 border mr-1" :disabled="disabledDF">
													%
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>						

				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<label v-if="inspeccion.observacionesGenerales" for="observacionesGenerales" class="font-weight-bold">Observaciones Generales</label>
						<textarea id="observacionesGenerales" class="form-control border" v-model="inspeccion.observacionesGenerales" placeholder="Observaciones Generales" :disabled="disabledDF" rows="5" maxlength="750"></textarea>
					</div>
				</div>

				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<h3>
							<a href="#" v-on:click.prevent="ventanas.fotos=!ventanas.fotos">
								FOTOS <span v-if="fotosCount>0" class="badge badge-primary">{{fotosCount}}</span>
							</a>
						</h3>
						<div class="row" v-show="ventanas.fotos">
							<div class="col-md-3 mb-3" v-for="(foto, index) in fotos" :key="index">
								<img v-lazy="$filters.storageMin(foto)" class="img-fluid rounded shadow" :alt="'Vehículo'+(index+1)">
							</div>
							<div class="col-md-3 mb-3" v-for="(foto, index) in inspeccion.fotos" :key="index">
								<img v-lazy="$filters.storageMin(foto)" class="img-fluid rounded shadow" :alt="'Vehículo'+(index+1)">
							</div>
							<div class="col-md-3" v-if="!disabledDF">
								<Upload class="mb-3 text-center w-100 cursor-pointer border-dashed rounded-sm overflow-hidden bg-white p-5" name="fotos" v-on:fileChange="uploadFileFotos($event)" :maxFiles="1">
									<img src="../../assets/image/cruz.webp" class="img-fluid mx-auto" alt="Agregar foto" title="Agregar foto">
								</Upload>
							</div>
						</div>
					</div>
				</div>

				<div v-if="!disabledDF && user.prf==3">
					<div class="d-flex justify-content-end">
						<button type="submit" class="btn btn-primary rounded-pill font-weight-bold mr-3" :disabled="loading && validateForm">
							<BtnLoading v-if="loadingSave" />
							<span v-else>
								<span class="material-icons align-middle">save</span> Guardar
							</span>
						</button>
						<button type="button" v-if="vehiculo.estatus == 0" v-on:click="validateUpdate(1)" class="btn btn-success rounded-pill font-weight-bold mr-3" :disabled="loading">
							<BtnLoading v-if="loadingAutorizar" />
							<span v-else>
								<span class="material-icons align-bottom">check</span>
								Autorizar
							</span>
						</button>
						<button type="button" v-if="vehiculo.estatus == 0" v-on:click="validateUpdate(4)" class="btn btn-danger rounded-pill font-weight-bold mr-3" :disabled="loading">
							<BtnLoading v-if="loadingRechazar" />
							<span v-else>
								<span class="material-icons align-bottom">close</span>
								Rechazar
							</span>
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		props: {
			datos: Object,
			disabled: Boolean
		},
		components: {
			BtnLoading,
			Upload
		},
		data(){
			return {
				inspeccionVehiculo: 0,
				loadingSave: false,
				inspeccion: {
					idVehiculo: '',
					chasis: '',
					placa: '',
					precioBanco: 0,
					bolsasAire: 0,
					cinturonSeguridadDelantero: false,
					cinturonSeguridadTraseros: false,
					tapiceriaAsientos: false,
					tapiceriaAsientosTipo: '',
					herramientaExtra: false,
					cableCorriente: false,
					extintor: false,
					botiquin: false,
					ticketManivela: false,
					llaveChuchos: false,
					triangulosReflectivos: false,
					cristalesRetrovisores: false,
					tarjetaCirculacion: false,
					platosTapones: 0,
					antena: false,
					alfombras: false,
					estadoBateria: false,
					estadoBateriaObservaciones: '',
					lucesDelanterasTraseras: false,
					lucesDelanterasTraserasObservaciones: '',
					lucesDireccionalesRetroceso: false,
					lucesDireccionalesRetrocesoObservaciones: '',
					limpiaParabrisas: false,
					limpiaParabrisasObservaciones: '',
					bocinaClaxon: false,
					bocinaClaxonObservaciones: '',
					alternadorMotorArranque: false,
					alternadorMotorArranqueObservaciones: '',
					tableroIndicaciones: false,
					tableroIndicacionesObservaciones: '',
					estadoMotor: false,
					estadoMotorObservaciones: '',
					embragueTransmisionManual: false,
					embragueTransmisionManualObservaciones: '',
					tranccion: false,
					tranccionObservaciones: '',
					frenoManoPie: false,
					frenoManoPieObservaciones: '',
					alineacion: false,
					alineacionObservaciones: '',
					balanceoRuedas: false,
					balanceoRuedasObservaciones: '',
					aceiteMotor: false,
					aceiteMotorObservaciones: '',
					vidriosEspejos: false,
					vidriosEspejosObservaciones: '',
					cerraduras: false,
					cerradurasObservaciones: '',
					inyeccionBomba: false,
					inyeccionBombaObservaciones: '',
					tanqueCombustible: false,
					tanqueCombustibleObservaciones: '',
					bujiasCablesAlta: false,
					bujiasCablesAltaObservaciones: '',
					diferenciaDelantero: false,
					diferenciaDelanteroObservaciones: '',
					diferenciaTrasero: false,
					diferenciaTraseroObservaciones: '',
					cajaTransfer: false,
					cajaTransferObservaciones: '',
					cajaCambios: false,
					cajaCambiosObservaciones: '',
					direccionHidraulica: false,
					direccionHidraulicaObservaciones: '',
					nivelFluidos: false,
					nivelFluidosObservaciones: '',
					radiadorMagueras: false,
					radiadorMaguerasObservaciones: '',
					correaBombaAgua: false,
					correaBombaAguaObservaciones: '',
					suspensionAmortiguacion: false,
					suspensionAmortiguacionObservaciones: '',
					sistemaDireccion: false,
					sistemaDireccionObservaciones: '',
					sistemaEscape: false,
					sistemaEscapeObservaciones: '',
					chasisCarroceria: false,
					chasisCarroceriaObservaciones: '',
					porcLlantas: 0,
					condicionesLlantas: false,
					llantaRepuesto: 0,
					llanta1: 0,
					llanta2: 0,
					llanta3: 0,
					llanta4: 0,
					observacionesGenerales: '',
					foto: '',
					fotos: []
				},
				ventanas: {
					seguridad: true,
					electrico: true,
					pruebas: true,
					condiciones: true,
					fotos: true
				},
				loadingAutorizar: false,
				loadingRechazar: false,
				validateForm: false,
				fotos: []
			}
		},
		mounted(){
			this.getInfo()
			this.getInfoFotos()
		},
		methods:{
			validateInspeccion(){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas guardar esta información?`,
					buttons: {
						si: function () {
							mod.sendInspeccion()
				    	},
				    	no: function () {

				    	},
				    }
				})
			},
			getInteres(){
				this.loadingUpdate = true
				this.$store.dispatch('getInteres', {
					idVehiculo: this.vehiculo.idVehiculo,
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingUpdate = false
				})
			},
			getInfo(){
				if (!this.vehiculo.idVehiculo) {return}
				this.$store.dispatch('getInspeccion', {
					idVehiculo: this.vehiculo.idVehiculo
				}).then(response => {
					var data = response.data || {}
					this.inspeccionVehiculo = data.idVehiculo
					this.inspeccion.chasis =  data.chasis || ''
					this.inspeccion.placa =  data.placa || ''
					this.inspeccion.precioBanco =  data.precioBanco || 0
					this.inspeccion.bolsasAire =  data.bolsasAire || 0
					this.inspeccion.cinturonSeguridadDelantero =  data.cinturonSeguridadDelantero == 1 ? true : false
					this.inspeccion.cinturonSeguridadTraseros =  data.cinturonSeguridadTraseros == 1 ? true : false
					this.inspeccion.tapiceriaAsientos =  data.tapiceriaAsientos == 1 ? true : false
					this.inspeccion.tapiceriaAsientosTipo =  data.tapiceriaAsientosTipo || ''
					this.inspeccion.herramientaExtra =  data.herramientaExtra == 1 ? true : false
					this.inspeccion.cableCorriente =  data.cableCorriente == 1 ? true : false
					this.inspeccion.extintor =  data.extintor == 1 ? true : false
					this.inspeccion.botiquin =  data.botiquin == 1 ? true : false
					this.inspeccion.ticketManivela =  data.ticketManivela == 1 ? true : false
					this.inspeccion.llaveChuchos =  data.llaveChuchos == 1 ? true : false
					this.inspeccion.triangulosReflectivos =  data.triangulosReflectivos == 1 ? true : false
					this.inspeccion.cristalesRetrovisores =  data.cristalesRetrovisores == 1 ? true : false
					this.inspeccion.tarjetaCirculacion =  data.tarjetaCirculacion == 1 ? true : false
					this.inspeccion.platosTapones =  data.platosTapones || 0
					this.inspeccion.antena =  data.antena == 1 ? true : false
					this.inspeccion.alfombras =  data.alfombras == 1 ? true : false
					this.inspeccion.estadoBateria =  data.estadoBateria == 1 ? true : false
					this.inspeccion.estadoBateriaObservaciones =  data.estadoBateriaObservaciones || ''
					this.inspeccion.lucesDelanterasTraseras =  data.lucesDelanterasTraseras == 1 ? true : false
					this.inspeccion.lucesDelanterasTraserasObservaciones =  data.lucesDelanterasTraserasObservaciones || ''
					this.inspeccion.lucesDireccionalesRetroceso =  data.lucesDireccionalesRetroceso == 1 ? true : false
					this.inspeccion.lucesDireccionalesRetrocesoObservaciones =  data.lucesDireccionalesRetrocesoObservaciones || ''
					this.inspeccion.limpiaParabrisas =  data.limpiaParabrisas == 1 ? true : false
					this.inspeccion.limpiaParabrisasObservaciones =  data.limpiaParabrisasObservaciones || ''
					this.inspeccion.bocinaClaxon =  data.bocinaClaxon == 1 ? true : false
					this.inspeccion.bocinaClaxonObservaciones =  data.bocinaClaxonObservaciones || ''
					this.inspeccion.alternadorMotorArranque =  data.alternadorMotorArranque == 1 ? true : false
					this.inspeccion.alternadorMotorArranqueObservaciones =  data.alternadorMotorArranqueObservaciones || ''
					this.inspeccion.tableroIndicaciones =  data.tableroIndicaciones == 1 ? true : false
					this.inspeccion.tableroIndicacionesObservaciones =  data.tableroIndicacionesObservaciones || ''
					this.inspeccion.estadoMotor =  data.estadoMotor == 1 ? true : false
					this.inspeccion.estadoMotorObservaciones =  data.estadoMotorObservaciones || ''
					this.inspeccion.embragueTransmisionManual =  data.embragueTransmisionManual == 1 ? true : false
					this.inspeccion.embragueTransmisionManualObservaciones =  data.embragueTransmisionManualObservaciones || ''
					this.inspeccion.tranccion =  data.tranccion == 1 ? true : false
					this.inspeccion.tranccionObservaciones =  data.tranccionObservaciones || ''
					this.inspeccion.frenoManoPie =  data.frenoManoPie == 1 ? true : false
					this.inspeccion.frenoManoPieObservaciones =  data.frenoManoPieObservaciones || ''
					this.inspeccion.alineacion =  data.alineacion == 1 ? true : false
					this.inspeccion.alineacionObservaciones =  data.alineacionObservaciones || ''
					this.inspeccion.balanceoRuedas =  data.balanceoRuedas == 1 ? true : false
					this.inspeccion.balanceoRuedasObservaciones =  data.balanceoRuedasObservaciones || ''
					this.inspeccion.aceiteMotor =  data.aceiteMotor == 1 ? true : false
					this.inspeccion.aceiteMotorObservaciones =  data.aceiteMotorObservaciones || ''
					this.inspeccion.vidriosEspejos =  data.vidriosEspejos == 1 ? true : false
					this.inspeccion.vidriosEspejosObservaciones =  data.vidriosEspejosObservaciones || ''
					this.inspeccion.cerraduras =  data.cerraduras == 1 ? true : false
					this.inspeccion.cerradurasObservaciones =  data.cerradurasObservaciones || ''
					this.inspeccion.inyeccionBomba =  data.inyeccionBomba == 1 ? true : false
					this.inspeccion.inyeccionBombaObservaciones =  data.inyeccionBombaObservaciones || ''
					this.inspeccion.tanqueCombustible =  data.tanqueCombustible == 1 ? true : false
					this.inspeccion.tanqueCombustibleObservaciones =  data.tanqueCombustibleObservaciones || ''
					this.inspeccion.bujiasCablesAlta =  data.bujiasCablesAlta == 1 ? true : false
					this.inspeccion.bujiasCablesAltaObservaciones =  data.bujiasCablesAltaObservaciones || ''
					this.inspeccion.diferenciaDelantero =  data.diferenciaDelantero == 1 ? true : false
					this.inspeccion.diferenciaDelanteroObservaciones =  data.diferenciaDelanteroObservaciones || ''
					this.inspeccion.diferenciaTrasero =  data.diferenciaTrasero == 1 ? true : false
					this.inspeccion.diferenciaTraseroObservaciones =  data.diferenciaTraseroObservaciones || ''
					this.inspeccion.cajaTransfer =  data.cajaTransfer == 1 ? true : false
					this.inspeccion.cajaTransferObservaciones =  data.cajaTransferObservaciones || ''
					this.inspeccion.cajaCambios =  data.cajaCambios == 1 ? true : false
					this.inspeccion.cajaCambiosObservaciones =  data.cajaCambiosObservaciones || ''
					this.inspeccion.direccionHidraulica =  data.direccionHidraulica == 1 ? true : false
					this.inspeccion.direccionHidraulicaObservaciones =  data.direccionHidraulicaObservaciones || ''
					this.inspeccion.nivelFluidos =  data.nivelFluidos == 1 ? true : false
					this.inspeccion.nivelFluidosObservaciones =  data.nivelFluidosObservaciones || ''
					this.inspeccion.radiadorMagueras =  data.radiadorMagueras == 1 ? true : false
					this.inspeccion.radiadorMaguerasObservaciones =  data.radiadorMaguerasObservaciones || ''
					this.inspeccion.correaBombaAgua =  data.correaBombaAgua == 1 ? true : false
					this.inspeccion.correaBombaAguaObservaciones =  data.correaBombaAguaObservaciones || ''
					this.inspeccion.suspensionAmortiguacion =  data.suspensionAmortiguacion == 1 ? true : false
					this.inspeccion.suspensionAmortiguacionObservaciones =  data.suspensionAmortiguacionObservaciones || ''
					this.inspeccion.sistemaDireccion =  data.sistemaDireccion == 1 ? true : false
					this.inspeccion.sistemaDireccionObservaciones =  data.sistemaDireccionObservaciones || ''
					this.inspeccion.sistemaEscape =  data.sistemaEscape == 1 ? true : false
					this.inspeccion.sistemaEscapeObservaciones =  data.sistemaEscapeObservaciones || ''
					this.inspeccion.chasisCarroceria =  data.chasisCarroceria == 1 ? true : false
					this.inspeccion.chasisCarroceriaObservaciones =  data.chasisCarroceriaObservaciones || ''
					this.inspeccion.porcLlantas =  data.porcLlantas || 0
					this.inspeccion.condicionesLlantas =  data.condicionesLlantas == 1 ? true : false
					this.inspeccion.llantaRepuesto =  data.llantaRepuesto || 0
					this.inspeccion.llanta1 =  data.llanta1 || 0
					this.inspeccion.llanta2 =  data.llanta2 || 0
					this.inspeccion.llanta3 =  data.llanta3 || 0
					this.inspeccion.llanta4 =  data.llanta4 || 0
					this.inspeccion.foto = data.foto || ''
					this.inspeccion.observacionesGenerales =  data.observacionesGenerales || ''
					return response
				}).catch(error =>{
					return error
				})
			},
			getInfoFotos(){
				if (!this.vehiculo.idVehiculo) {return}
				this.$store.dispatch('getInspeccionFotos', {
					idVehiculo: this.vehiculo.idVehiculo
				}).then(response => {
					this.fotos = (response.data || []).map(x => x.ruta)
					return response
				}).catch(error =>{
					return error
				})
			},
			sendInspeccion(){
				if (this.disabledDF && this.user.prf != 3) {
					return
				}
				this.checkValidity()
				if (!this.validateForm) {
					window.toastr.error("Existen campos no válidos.", "Error")
					return
				}
				this.loadingSave = true
				this.inspeccion.idVehiculo = this.vehiculo.idVehiculo
				this.$store.dispatch('addInspeccion', this.inspeccion).then(response => {
					window.toastr.success("La gestión ha sido realizada con éxito.", "Éxito")
					this.getInfo()
					this.getInfoFotos()
					this.inspeccion.fotos = []
					return response
				}).catch(error =>{
					window.toastr.error('Error al guardar registro, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingSave = false
				})
			},
			validateUpdate(estado){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas ${estado == 1 ? 'autorizar' : 'rechazar'} este vehículo?`,
					buttons: {
						si: function () {
							mod.vehiculoUpdateStatus(estado)
				    	},
				    	no: function () {

				    	},
				    }
				})
			},
			vehiculoUpdateStatus(estado){
				if (estado==1) {
					this.loadingAutorizar = true
				}
				if (estado==4) {
					this.loadingRechazar = true
				}
				this.$store.dispatch('vehiculoUpdateStatus', {
					idVehiculo: this.vehiculo.idVehiculo,
					estatus: estado
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					if (estado==1) {
						this.getInteres()
					}
					this.$router.push({name: 'admin.vehiculos.pendientes'})
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					if (estado==1) {
						this.loadingAutorizar = false
					}
					if (estado==4) {
						this.loadingRechazar = false
					}
				})
			},
			checkValidity(){
				if (!this.$refs.inspeccionForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.inspeccionForm.checkValidity()
				}
			},
			uploadFile(file){
				this.loadingFile = true
				var formData = new FormData()
				var nameFile = this.uniqueString()+'-'+file.name
				formData.append('files', file)
		        formData.append('nameFile', nameFile)
				this.$store.dispatch('vehiculosUploadInspeccion', formData).then(response => {
					this.inspeccion.foto = 'https://clubcashin.com/storage/inspecciones/'+nameFile
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},
			uploadFileFotos(file){
				this.loadingFileFotos = true
				var formData = new FormData()
				var nameFile = this.uniqueString()+'-'+file.name
				formData.append('files', file)
		        formData.append('nameFile', nameFile)
				this.$store.dispatch('vehiculosUploadInspeccion', formData).then(response => {
					this.inspeccion.fotos.push('https://clubcashin.com/storage/inspecciones/'+nameFile)
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFileFotos = false
				})
			},
			uniqueString() {
				var text     = ""
				var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
				for( var i=0; i < 8; i++ ) {
					text += possible.charAt(Math.floor(Math.random() * (possible.length || 0)))
				}
			    return text;
			},
		},
		computed: {
			vehiculo(){
				return this.datos || {}
			},
			llantas(){
				return parseInt(this.inspeccion.llantaRepuesto || 0) + parseInt(this.inspeccion.llanta1 || 0) + parseInt(this.inspeccion.llanta2 || 0) + parseInt(this.inspeccion.llanta3 || 0) + parseInt(this.inspeccion.llanta4 || 0)
			},
			porcLlantas(){
				return this.inspeccion.porcLlantas
			},
			disabledDF(){
				return this.disabled
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			loading(){
				return this.$store.getters.loading
			},
			fotosCount(){
				return parseInt(this.fotos.length) + parseInt(this.inspeccion.fotos.length)
			},
			validateShow(){
				if (!this.isLoggedIn) {
					return false
				}
				return (this.user.idusuario === this.vehiculo.idUsuario || this.user.prf == 3)
			}
		},
		watch: {
			llantas(val){
				this.inspeccion.porcLlantas = parseInt(parseInt(val || 0)/5)
			},
			porcLlantas(val){
				if (val<=50) {
					this.inspeccion.condicionesLlantas = false
				}else{
					this.inspeccion.condicionesLlantas = true
				}
			},
			'vehiculo': {
				handler(val){
					this.getInfo()
					this.getInfoFotos()
					return val
				},
				deep: true
			},
			'inspeccion':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
		}
	}
</script>