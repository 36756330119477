<template>
	<div>
		<div>
			<img src="@/assets/image/autocompras_portada.webp" class="w-100 h-100">
		</div>

		<div class="bg-white py-5">
			<h2 class="text-center display-4 font-weight-bold my-3">COMPRA TU CARRO SEMINUEVO</h2>

			<div class="d-flex justify-content-center mt-3">
				<div class="col-lg-8 mb-3">
					<h3 class="h1 font-weight-light">
						La mejor opción para comprar un carro a través de un proceso 100% digital.
					</h3>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-white" v-if="vehiculos.length>0">
			<div class="row justify-content-center">
				<div class="col-lg-7 mb-3">
					<div class="row justify-content-between">
						<div class="col-lg mb-3 text-center mr-lg-3" v-if="marcas.length>0">
							<button :class="{'btn btn-secondary btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('marca')">
								<div class="d-flex justify-content-between">
									<span>
										Marca <span v-if="filters.marcas.length>0">({{filters.marcas.length}})</span>
									</span>
								 	<div class="ml-3">
										<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'ml-3':true, 'rotate-180':showFilter == 'marca'}" height="13">
								 	</div>
								</div>
							</button>
						</div>

						<div class="col-lg mb-3 text-center mr-lg-3" v-if="anios.length>0">
							<button :class="{'btn btn-secondary btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('anios')">
								<div class="d-flex justify-content-between">
									<span>
										Año <span v-if="filters.anios.length>0">({{filters.anios.length}})</span>
									</span>
								 	<div class="ml-3">
								 		<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'ml-3':true, 'rotate-180':showFilter == 'anios'}" height="13">
								 	</div>
								</div>
							</button>
						</div>

						<div class="col-lg-5 mb-3">
							<button :class="{'btn btn-secondary w-100 btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('precios')">
								<div class="d-flex justify-content-between">
									<span>Rango de precio</span>
								 	<div class="ml-3">
								 		<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'rotate-180':showFilter == 'precios'}" height="13">
								 	</div>
								</div>
							</button>
						</div>
					</div>
					<div class="row justify-content-between">
						<div class="col-lg mb-3 text-center mr-lg-3" v-if="origenes.length>0">
							<button :class="{'btn btn-secondary btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('origenes')">
								<div class="d-flex justify-content-between">
									<span>
										Origen <span v-if="filters.origenes.length>0">({{filters.origenes.length}})</span>
									</span>
								 	<div class="ml-3">
										<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'ml-3':true, 'rotate-180':showFilter == 'origenes'}" height="13">
								 	</div>
								</div>
							</button>
						</div>

						<div class="col-lg mb-3 text-center mr-lg-3" v-if="tipos.length>0">
							<button :class="{'btn btn-secondary btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('tipos')">
								<div class="d-flex justify-content-between">
									<span>
										Tipo <span v-if="filters.tipos.length>0">({{filters.tipos.length}})</span>
									</span>
								 	<div class="ml-3">
										<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'ml-3':true, 'rotate-180':showFilter == 'tipos'}" height="13">
								 	</div>
								</div>
							</button>
						</div>

						<div class="col-lg-5 mb-3" v-if="transmisiones.length>0">
							<button :class="{'btn btn-secondary w-100 btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('transmisiones')">
								<div class="d-flex justify-content-between">
									<span>
										Transmisión <span v-if="filters.transmisiones.length>0">({{filters.transmisiones.length}})</span>
									</span>
								 	<div class="ml-3">
										<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'rotate-180':showFilter == 'transmisiones'}" height="13">
								 	</div>
								</div>
							</button>
						</div>
					</div>
				</div>
				<div class="col-lg-2 mb-3" v-if="vehiculosFiltered.length != vehiculos.length">
					<div class="d-flex align-items-end h-100">
						<button type="button" class="btn btn-outline-danger mb-3" v-on:click="clearFilters()">
							Limpiar filtros
						</button>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='marca'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row">
								<div v-for="(marca, index) in marcasFilters" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" :id="'marca'+index" :value="marca.idMarca" v-model="filters.marcas">
										<label class="custom-control-label" :for="'marca'+index">
											{{marca.descripcion}}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='origenes'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row justify-content-center">
								<div class="col-lg-3 mb-3" v-for="(origen, index) in origenes" :key="index">
									<div class="custom-control custom-switch">
										<input type="checkbox" :id="'origenes'+index" class="custom-control-input" :value="origen.origen" v-model="filters.origenes">
										<label class="custom-control-label" :for="'origenes'+index">
											<small>{{origen.descripcion}}</small>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='anios'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row">
								<div v-for="(anio, index) in aniosFilters" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" :id="'anio'+index" :value="anio" v-model="filters.anios">
										<label class="custom-control-label" :for="'anio'+index">
											{{anio}}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='tipos'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row">
								<div v-for="(tipo, index) in tipos" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" :id="'anio'+index" :value="tipo.idTipo" v-model="filters.tipos">
										<label class="custom-control-label" :for="'anio'+index">
											{{tipo.descripcion}}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='transmisiones'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row justify-content-center">
								<div v-for="(transmision, index) in transmisiones" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" :id="'transmisiones'+index" class="custom-control-input" :value="transmision.transmision" v-model="filters.transmisiones">
										<label class="custom-control-label" :for="'transmisiones'+index">
											<small>{{transmision.descripcion}}</small>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='precios'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div v-if="filters.precio[0]>= 0 && filters.precio[1]>=0" class="mb-3">
								<p class="text-primary font-weight-bold mb-0">Precio (Q)</p>
								<div class="form-row mb-2">
									<div class="col-6">
										<CurrencyInput v-model="filters.precio[0]" type="text" placeholder="desde" class="form-control border" :min="precio.min" />
									</div>
									<div class="col-6">
										<CurrencyInput v-model="filters.precio[1]" type="text" placeholder="hasta" class="form-control border" :min="precio.min" />
									</div>
								</div>
								<Slider v-model="filters.precio" :min="precio.min" :max="1000000" :tooltips="false" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="py-5">
			<div class="container-fluid">
				<div class="row justify-content-center" v-if="vehiculosFiltered.length>0">
					<div class="col-lg-9" v-if="!isLoggedIn">
						<div class="alert alert-primary alert-dismissible fade show" role="alert">
							Recuerda para poder solicitar un crédito debes estar <strong><router-link :to="{name:'register'}">registrado</router-link></strong> o <strong><router-link :to="{name:'login'}">iniciar sesion</router-link></strong>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>

					<div class="w-100 d-none d-md-block"></div>

					<template v-for="(vehiculo, index) in vehiculosFilteredPagination" :key="index">
						<div class="col-lg-12 px-0" v-if="index == 9 && pagination.current_page==1">
							<div class="my-5 bg-credito text-center">
								<img src="@/assets/image/autocompras-banner.webp" :class="{'img-fluid':isMobile, 'w-100':!isMobile}">
							</div>
						</div>

						<div class="col-lg-3 mb-3">
							<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}">
								<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculo.estatus!=1}">
									<img v-lazy="$filters.storage(vehiculo.ruta, 200)" class="w-100 h-180 rounded-top" :alt="vehiculo.marca" style="object-fit: cover; height: 160px;" v-if="vehiculo.ruta">
									<div v-if="vehiculo.idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
										Oferta realizada
									</div>
									<div v-if="vehiculo.estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
										Disponible
									</div>
									<div v-if="vehiculo.estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
										Apartado
									</div>
									<div v-if="vehiculo.estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
										Vendido
									</div>

									<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
										{{$filters.currency(vehiculo.precio)}}
									</div>
								</div>
								<div class="rounded-bottom bg-primary text-white py-2 px-3">
									<div class="text-truncate ">
										<span class="font-weight-bold">{{vehiculo.marca}} {{vehiculo.modelo}}</span>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<div class="font-small align-items-center d-flex">
											<span class="material-icons align-self-center mr-1">event</span> {{vehiculo.ano}}
										</div>
										<div class="font-small align-items-center d-flex">
											<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculo.numeroPasajeros}}
										</div>
										<div class="font-small align-items-center d-flex justify-content-end">
											{{vehiculo.desTransmision}}
										</div>
									</div>
								</div>
							</router-link>
						</div>

						<div class="w-100 d-none d-md-block" v-if="index % 3 == 2"></div>

					</template>
				</div>

				<div class="row justify-content-center" v-else>
					<div class="col-lg-8 col-xl-9 mb-3">
						<div>
							<div class="text-center py-5" v-if="vehiculos.length>0">
								<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
									<img src="@/assets/image/search_dark.webp" class="img-fluid">
								</div>
								<h5>
									No se encontraron resultados en la búsqueda.
								</h5>
							</div>
							<div :class="{'text-center py-5 my-3':true, 'bg-light rounded':!loadingVehiculos}" v-else>
								<div v-if="loadingVehiculos" class="text-center my-5">
									<PreloaderAutoCompras />
								</div>
								<div v-else>
									<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
										<img src="@/assets/image/search_dark.webp" class="img-fluid">
									</div>
									<h5>
										Aún no hay vehículos disponibles.
									</h5>
									<router-link class="btn btn-primary font-weight-bold" :to="{name:'vehiculos.create'}">
										<span class="material-icons align-bottom">add_a_photo</span>
										Publicar vehículo
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="text-center" v-if="vehiculosFiltered.length>0">
					<Paginacion :pagination="pagination" @paginate="changePagination" :offset="4" class="d-flex justify-content-center" />
				</div>
			</div>
		</div>

		<div class="bg-white py-5 container-fluid">
			<h3 class="display-4 font-weight-bold text-center mb-3">OTRAS OPCIONES PARA TÍ</h3>
			<PrestamosProductos omit="AutoCompras"></PrestamosProductos>
		</div>

		<div class="container-fluid bg-light py-5">
			<div class="row justify-content-center align-items-center">
				<div class="col-lg-5 mb-3">
					<h2 class="display-4 font-weight-bold mb-3">
						¿Quieres vender tu <br> carro en nuestro <br> marketplace?
					</h2>

					<div class="text-center">
						<router-link class="btn btn-secondary font-weight-bold" :to="{name:'vehiculos.create'}">
							Publica tu carro
						</router-link>
					</div>
				</div>
				<div class="col-lg-6 mb-3">
					<div class="embed-responsive embed-responsive-16by9">
						<iframe  class="rounded embed-responsive-item" src="https://www.youtube.com/embed/Xq4EsdtnyBo?version=3&loop=1&playlist=Xq4EsdtnyBo&controls=0&rel=0" title="Vende tu carro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Paginacion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pagination" */"@/components/Pagination.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const PrestamosProductos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrestamosProductos" */"@/components/prestamos/Productos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slider = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Slider" */"@vueform/slider"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const PreloaderAutoCompras = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PreloaderAutoCompras" */"@/components/PreloaderAutoCompras.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			Slider,
			CurrencyInput,
			PreloaderAutoCompras,
			Paginacion,
			PrestamosProductos
		},
		data(){
			return {
				loadingVehiculos: false,
				vehiculos: [],
				filters: {
					min: 0,
					max: 0,
					estado: [1,5],
					idTipo: '',
					idColor: '',
					idMarca: '',
					idModelo: '',

					marcas: [],
					modelos: [],
					tipos: [],
					anios: [],
					combustibles: [],
					transmisiones: [],
					origenes: [],
					precio: [0, 0],
					kilometraje: [0, 100],
				},
				pagination: {
					per_page: 18,
		            current_page: 1,
		            total: 50
		        },
				showFilters: false,
				showFilter: '',
				colors: [],
				tipos: [],
				marcas: [],
				modelos: [],
				anios: [],
				combustibles: [],
				transmisiones: [],
				origenes: [],
				precio: {
					min: 0,
					max: 100
				},

				searchModelos: ''
			}
		},
		mounted(){
			//this.getCatalogos()
			this.getVehiculosDisponibles()
		},
		methods:{
			toggleFilter(index){
				if(this.showFilter == index){
					this.showFilter = ''
				}else{
					this.showFilter = index
				}
			},
			changePagination(page){
				this.pagination.current_page = page
				//this.getPublicacionesDisp()
			},

			getVehiculosDisponibles(){
				//if (!this.user.idusuario) {return}
				this.loadingVehiculos = true
				this.$store.dispatch('getVehiculosDisponibles', {
					idUsuarioVehiculo: this.user.idusuario
				}).then(response => {
					this.vehiculos = (response.data || [])
					this.getCatalogos()
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
			clearFilters(){
				this.filters.min = 0
				this.filters.max = 0
				this.filters.estado = [1,5]
				this.filters.idTipo = ''
				this.filters.idColor = ''
				this.filters.idMarca = ''
				this.filters.idModelo = ''

				this.filters.marcas = []
				this.filters.modelos = []
				this.filters.tipos = []
				this.filters.anios = []
				this.filters.combustibles = []
				this.filters.transmisiones = []
				this.filters.origenes = []
				
				this.precio.min = 0
				this.precio.max = 0

				this.filters.precio = [0, 0]
				this.filters.kilometraje = [0, 100]
				
				this.getCatalogos()
			},
			filtersDesktop(val){
				this.showFilters = val
			},
			amountMax(increment=true){
				if (increment) {
					this.filters.max++
				}else{
					if (this.filters.max<=0) {return}
					this.filters.max--
				}
				//this.loadData()
			},
			amountMin(increment=true){
				if (increment) {
					this.filters.min++
				}else{
					if (this.filters.min<=0) {return}
					this.filters.min--
				}
				//this.loadData()
			},
			getCatalogos(val='') {
				if(val!='modelos'){
					this.modelos = []
				}
				if(val!='marcas'){
					this.marcas = []
				}
				if(val!='colors'){
					this.colors = []
				}
				if(val!='anios'){
					this.anios = []
				}
				if(val!='tipos'){
					this.tipos = []
				}
				if(val!='combustibles'){
					this.combustibles = []
				}
				if(val!='transmisiones'){
					this.transmisiones = []
				}
				if(val!='origenes'){
					this.origenes = []
				}
				if(val!='precio'){
					this.precio.min = 0
					this.precio.max = 0
				}

				for(var i = 0;i<this.vehiculosFiltered.length; i++){
					if(this.modelos.findIndex(x => x.idModelo == this.vehiculosFiltered[i]['idModelo']) == -1){
						this.modelos.push({idModelo: this.vehiculosFiltered[i]['idModelo'] || -1, descripcion: this.vehiculosFiltered[i]['modelo']})
					}
					if(this.marcas.findIndex(x => x.idMarca == this.vehiculosFiltered[i]['idMarca']) == -1){
						this.marcas.push({idMarca: this.vehiculosFiltered[i]['idMarca'] || -1, descripcion: this.vehiculosFiltered[i]['marca']})
					}
					if(this.colors.findIndex(x => x.idColor == this.vehiculosFiltered[i]['idColor']) == -1){
						this.colors.push({idColor: this.vehiculosFiltered[i]['idColor'] || -1, descripcion: this.vehiculosFiltered[i]['color']})
					}
					if(!this.anios.includes(this.vehiculosFiltered[i]['ano'])){
						this.anios.push(this.vehiculosFiltered[i]['ano'])
					}
					if(this.tipos.findIndex(x => x.idTipo == this.vehiculosFiltered[i]['idTipo']) == -1){
						this.tipos.push({idTipo: this.vehiculosFiltered[i]['idTipo'] || -1, descripcion: this.vehiculosFiltered[i]['tipo']})
					}
					if(this.combustibles.findIndex(x => x.tipoCombustible == this.vehiculosFiltered[i]['tipoCombustible']) == -1){
						this.combustibles.push({tipoCombustible: this.vehiculosFiltered[i]['tipoCombustible'] || -1, descripcion: this.vehiculosFiltered[i]['combustible']})
					}
					if(this.transmisiones.findIndex(x => x.transmision == this.vehiculosFiltered[i]['transmision']) == -1){
						this.transmisiones.push({transmision: this.vehiculosFiltered[i]['transmision'], descripcion: this.vehiculosFiltered[i]['desTransmision']})
					}

					if(this.origenes.findIndex(x => x.origen == this.vehiculosFiltered[i]['origen']) == -1){
						this.origenes.push({origen: this.vehiculosFiltered[i]['origen'], descripcion: this.vehiculosFiltered[i]['desOrigen']})
					}
				}
				if(this.precio.max==0){
					var dd = this.vehiculos.map(function(x){
						return x.precio || 0
					})
					this.precio.max = Math.max.apply(null, dd)
					this.filters.precio[1] = Math.max.apply(null, dd)
				}


				/*if (this.precio.max==0) {
					if ((this.vehiculosFiltered[i]['precio'] || 0) >= this.precio.max) {
						this.precio.max = this.vehiculosFiltered[i]['precio']
						if(val!='precio'){
							this.filters.precio[1] = this.vehiculosFiltered[i]['precio']
							//this.precioMax = this.vehiculosFiltered[i]['precio']
						}
					}
				}*/
			},
			equals(a, b){
				JSON.stringify(a) === JSON.stringify(b)
			}
		},
		computed:{
			isMobile() {
				return this.$store.getters.isMobile
			},
			aniosFilters(){
				return (this.anios || []).sort().reverse()
			},
			vehiculosFiltered(){
				return this.vehiculos.filter(x => {
					var dd = true
					if (this.filters.marcas.length>0) {
						if (!this.filters.marcas.includes(x.idMarca)) {
							dd = false
						}
					}
					if (this.filters.modelos.length>0) {
						if (!this.filters.modelos.includes(x.idModelo)) {
							dd = false
						}
					}
					if (this.filters.anios.length>0) {
						if (!this.filters.anios.includes(x.ano)) {
							dd = false
						}
					}
					if (this.filters.transmisiones.length>0) {
						if (!this.filters.transmisiones.includes(x.transmision)) {
							dd = false
						}
					}
					if (this.filters.origenes.length>0) {
						if (!this.filters.origenes.includes(x.origen)) {
							dd = false
						}
					}
					if (this.filters.tipos.length>0) {
						if (!this.filters.tipos.includes(x.idTipo)) {
							dd = false
						}
					}
					if ((this.filters.precio[0] || 0)>=0) {
						if (parseFloat(x.precio || 0) < parseFloat(this.filters.precio[0] || 0)) {
							dd = false
						}
					}
					if ((this.filters.precio[1] || 0)>0) {
						if (parseFloat(x.precio || 0) > parseFloat(this.filters.precio[1] || 0)) {
							dd = false
						}
					}
					return dd
				})
			},
			ini(){
				return ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
			},
			fin(){
				return ((this.pagination.current_page*this.pagination.per_page))
			},
			vehiculosFilteredPagination(){
				return this.vehiculosFiltered.slice(this.ini, this.fin)
			},

			isLoggedIn(){
				return this.$store.getters.isLoggedIn 
			},
			marcasOrder(){
				return (this.marcas || []).sort(function(a,b){ return a.descripcion > b.descripcion ? 1 : 0; })
			},
			marcasFilters(){
				return (this.marcasOrder || [])
			},
			modelosOrder(){
				return (this.modelos || []).sort(function(a,b){ return a.descripcion > b.descripcion ? 1 : 0; })
			},
			modelosFiltered(){
				var data = this.modelosOrder || []
				if (this.searchModelos) {
					return data.filter(x => x.descripcion.includes(this.searchModelos))
				}
				return data
			},
			showData(){
				return this.showFilters && this.vehiculos.length>0
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch: {
			vehiculos: {
				handler(val){
					this.pagination.total = (val.length || 0)
				},
				deep: true
			},
			'vehiculosFiltered': function(val){
				//this.getCatalogos()
				return val
			},
			'filters.modelos': function(val){
				this.getCatalogos('modelos')
				return val
			},
			'filters.marcas': function(val){
				this.getCatalogos('marcas')
				return val
			},
			'filters.colors': function(val){
				this.getCatalogos('colors')
				return val
			},
			'filters.anios': function(val){
				this.getCatalogos('anios')
				return val
			},
			'filters.tipos': function(val){
				this.getCatalogos('tipos')
				return val
			},
			'filters.combustibles': function(val){
				this.getCatalogos('combustibles')
				return val
			},
			'filters.transmisiones': function(val){
				this.getCatalogos('transmisiones')
				return val
			},
			'filters.origenes': function(val){
				this.getCatalogos('origenes')
				return val
			},
			'filters.precio': function(val){
				this.getCatalogos('precio')
				return val
			},
			'filters.max': function(val){
				if (val<0) {
					this.filters.max = 0
				}
				//this.loadData()
			},
			'filters.min': function(val){
				if (val<0) {
					this.filters.min = 0
				}
				//this.loadData()
			},
			'user':{
				handler(val){
					this.getVehiculosDisponibles()
					return val
				},
				deep: true
			}
		}
	}	
</script>