<template>
	<div class="modal fade" id="contactar" data-backdrop="static" role="dialog" aria-labelledby="contactarLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable" role="document">
			<form ref="formPropuesta" v-on:submit.prevent="sendPropuesta()" class="needs-validation was-validated w-100" novalidate>
			<div class="modal-content">
				<div class="modal-header bg-warning align-items-center">
					<h3 class="modal-title text-left w-100">
						<div class="d-flex align-items-center">
							<span class="material-icons mr-3">email</span>
							Contactar anunciante
						</div>
					</h3>
					<button type="button" class="btn btn-link" data-dismiss="modal" aria-label="Close" :disabled="loadingPropuesta">
						<img src="@/assets/image/close.webp" width="20" height="20">
					</button>
				</div>
				<div class="modal-body" v-show="viewInfo">
					<div class="d-flex justify-content-between align-items-center mb-3">
						<h5 class="text-info">Términos de la Comunicación:</h5>
						<button type="button" v-on:click="viewInfo=false" class="btn btn-link text-info">
							<span class="material-icons">arrow_back</span>
						</button>
					</div>

					<ol>
						<li class="mb-2">
							Recomendamos seguir los siguientes consejos para tener una mejor experiencia al comprar o vender un carro
						</li>
						<li class="mb-2">
							Contamos con <strong>puntos de encuentro seguros</strong> para mostrar o ver el carro que te interesa
						</li>
						<li class="mb-2">
							En caso de que decidas hacerlo fuera de estos puntos, recuerda:
							<ul>
								<li class="mb-2">
									Que sea un lugar público, seguro, preferiblemente dentro de una garita o talanquera.
								</li>
								<li class="mb-2">
									No realizar o recibir pagos en efectivo, que todo pago sea dentro del sistema bancario.
								</li>
								<li class="mb-2">
									Si recibes un cheque, verificar su legitimidad con el banco emisor y recuerda que un pago con cheque de otro banco, no se acredita sino hasta haber pasado la compensación.
								</li>
							</ul>
						</li>
					</ol>
				</div>
				<div class="modal-body" v-show="!viewInfo">
					<div class="w-100 mb-3">
						<label for="correo_electronico">Correo electrónico</label>
						<input id="correo_electronico" type="email" v-model="form.correo" class="form-control bg-light" placeholder="Correo electrónico" required>
					</div>
					<div class="w-100 mb-3">
						<label for="nombre">Nombre</label>
						<input id="nombre" type="text" v-model="form.nombre" class="form-control bg-light" placeholder="Nombre" required>
					</div>

					<div class="w-100 mb-3">
						<label for="telefono">Teléfono</label>
						<input id="telefono" type="tel" v-model="form.telefono" class="form-control bg-light" placeholder="Teléfono" required>
					</div>

					<div class="w-100 mb-3">
						<label for="observaciones">Observaciones</label>
						<textarea id="observaciones" v-model="form.observaciones" class="form-control bg-light" placeholder="Observaciones"></textarea>
					</div>

					<div>
						<button type="button" v-on:click="viewInfo=true" class="btn btn-link text-info">
							* Términos de la comunicación
						</button>
					</div>

					<span class="text-danger">{{message}}</span>
			      </div>
		    	<div class="modal-footer text-right">
		    		<button type="submit" class="btn btn-warning" :disabled="loadingPropuesta">
		    			<BtnLoading v-if="loadingPropuesta" />
						<span v-else>
							Enviar
						</span>
		    		</button>
		    		<button class="btn btn-link" data-dismiss="modal" :disabled="loadingPropuesta">
		        		Cancelar
		    		</button>
		    	</div>
		  	</div>
		  	</form>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components: {
			BtnLoading
		},
		data(){
		  return {
		  	loadingPropuesta: false,
		  	validateForm: false,
		  	form: {
		  		correo: '',
		  		nombre: '',
		  		telefono: '',
		  		observaciones: ''
		  	},
		  	message: '',
		  	viewInfo: false
		  }
		},
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				vm.toIndex()
			})
			this.form.correo = this.user.mail || ''
			this.form.nombre = `${this.user.nombre || ''} ${this.user.apellido || ''}`
			this.form.telefono = this.user.telefono || ''
			this.form.observaciones = `Me interesa el anuncio (${this.vehiculo.marca || ''} ${this.vehiculo.modelo || ''} ${this.vehiculo.ano || ''}) que he visto en clubcashin.com`
			this.checkValidity()
		},
		methods: {
			sendPropuesta(){
				this.message = ''
				this.checkValidity()
				if (!this.validateForm) {
					this.message = 'Ingresa los campos obligatorios'
					return
				}
				var params = {
					correo: this.form.correo || '',
					nombre: this.form.nombre || '',
					telefono: this.form.telefono || '',
					observaciones: this.form.observaciones || '',
		            idVehiculo: this.id
				}
				this.loadingPropuesta = true
				this.$store.dispatch('vehiculoContacto', params).then(response => {
					window.toastr.info("El mensaje ha sido envíado", "Éxito")
					this.form.correo = this.user.mail || ''
					this.form.nombre = `${this.user.nombre || ''} ${this.user.apellido || ''}`
					this.form.telefono = this.user.telefono || ''
					this.form.observaciones = `Me interesa el anuncio (${this.vehiculo.marca || ''} ${this.vehiculo.modelo || ''} ${this.vehiculo.ano || ''}) que he visto en clubcashin.com`
					window.$(this.$el).modal('hide')
					return response
				}).catch(error =>{
					window.toastr.error('Error al envíar mensaje', 'Error')
					return error
				}).then(() => {
					this.loadingPropuesta = false
				})
			},
			mailsSendProposal(params){
				this.$store.dispatch('mailsSendProposal', {
					nomusuario: this.propuesta.nombreprs,
	                apellido: this.propuesta.apellidoprs,
	                mail: this.propuesta.idusuarioprs,
	                nombrePrenda: this.propuesta.nombre,
	                idpropuesta: this.propuesta.idpropuesta,
	                fotoPrenda: this.propuesta.ruta,
	                nomusuarioInv: this.propuesta.nombreinv,
	                apellidoInv: this.propuesta.apellidoinv,
	                montoOfertado: params.monto,
	                tasa: params.tasa,
	                tasaPeriodo: this.propuesta.tasaPeriodo,
	                tiempoPeriodo: this.propuesta.tiempoPeriodo,
	                tiempopago: params.tiempopago,
	                fechaAprobado: Math.round(new Date().getTime()/1000.0)
				})
			},
			sendPush(){
				this.$store.dispatch('sendPush', {
					idusuario : this.propuesta.idusuarioprs, 
		        	title: '¡Tienes una nueva propuesta!', 
		        	message: 'Han realizado una propuesta para ' + this.propuesta.nombre
				})
			},
			checkValidity(){
				if (!this.$refs.formPropuesta){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.formPropuesta.checkValidity()
				}
			},
			toIndex(){
				this.$router.push({name: 'vehiculos.show', params:{id: this.id}})
			},
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			vehiculo(){
				return this.$store.getters.infoVehiculo || {}
			},
		},
		watch:{
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
			'vehiculo':{
				handler(val){
					this.form.correo = this.user.mail || ''
					this.form.nombre = `${this.user.nombre || ''} ${this.user.apellido || ''}`
					this.form.telefono = this.user.telefono || ''
					this.form.observaciones = `Me interesa el anuncio (${val.marca || ''} ${val.modelo || ''} ${val.ano || ''}) que he visto en clubcashin.com`
					return val
				},
				deep: true
			}
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>