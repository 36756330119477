<template>
	<div>
		<div>
			<img src="@/assets/image/autocompras-nuevos.webp" class="w-100 h-100">
		</div>

		<div class="bg-white py-5">
			<h2 class="text-center display-4 font-weight-bold my-3">COMPRA TU CARRO NUEVO</h2>

			<div class="d-flex justify-content-center mt-3">
				<div class="col-lg-8 mb-3">
					<h3 class="h1 font-weight-light">
						Financiamiento para comprar un carro <br> nuevo en cualquier agencia de vehículos
					</h3>
				</div>
			</div>
		</div>


		<div class="container-fluid bg-white" v-if="vehiculos.length>0">
			<div class="row justify-content-center">
				<div class="col-lg-7 mb-3">
					<div class="row justify-content-between">
						<div class="col-lg mb-3 text-center mr-lg-3" v-if="marcas.length>0">
							<button :class="{'btn btn-secondary btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('marca')">
								<div class="d-flex justify-content-between">
									<span>
										Marca <span v-if="filters.marcas.length>0">({{filters.marcas.length}})</span>
									</span>
								 	<div class="ml-3">
										<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'ml-3':true, 'rotate-180':showFilter == 'marca'}" height="13">
								 	</div>
								</div>
							</button>
						</div>

						<div class="col-lg mb-3 text-center mr-lg-3" v-if="tipos.length>0">
							<button :class="{'btn btn-secondary btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('tipos')">
								<div class="d-flex justify-content-between">
									<span>
										Tipo <span v-if="filters.tipos.length>0">({{filters.tipos.length}})</span>
									</span>
								 	<div class="ml-3">
										<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'ml-3':true, 'rotate-180':showFilter == 'tipos'}" height="13">
								 	</div>
								</div>
							</button>
						</div>

						<div class="col-lg mb-3">
							<button :class="{'btn btn-secondary w-100 btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('precios')">
								<div class="d-flex justify-content-between">
									<span>Precio</span>
								 	<div class="ml-3">
								 		<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'rotate-180':showFilter == 'precios'}" height="13">
								 	</div>
								</div>
							</button>
						</div>

						<div class="col-lg mb-3">
							<button :class="{'btn btn-secondary w-100 btn-lg px-3 py-0 font-weight-bold':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('cuota')">
								<div class="d-flex justify-content-between">
									<span>Cuota</span>
								 	<div class="ml-3">
								 		<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'rotate-180':showFilter == 'cuota'}" height="13">
								 	</div>
								</div>
							</button>
						</div>
					</div>
				</div>
				<div class="col-lg-2 mb-3" v-if="vehiculosFiltered.length != vehiculos.length">
					<div class="d-flex align-items-end h-100">
						<button type="button" class="btn btn-outline-danger mb-3" v-on:click="clearFilters()">
							Limpiar filtros
						</button>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='marca'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row">
								<div v-for="(marca, index) in marcasFilters" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" :id="'marca'+index" :value="marca.idMarca" v-model="filters.marcas">
										<label class="custom-control-label" :for="'marca'+index">
											{{marca.descripcion}}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='colors'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row justify-content-center">
								<div class="col-lg-3 mb-3" v-for="(color, index) in colors" :key="index">
									<div class="custom-control custom-switch">
										<input type="checkbox" :id="'colors'+index" class="custom-control-input" :value="color.idColor" v-model="filters.colors">
										<label class="custom-control-label" :for="'colors'+index">
											<span :style="{backgroundColor: color.rgba, width: '25px', height: '25px'}" class="rounded mr-1"></span>
											<span>
												{{color.descripcion}}
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='anios'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row">
								<div v-for="(anio, index) in aniosFilters" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" :id="'anio'+index" :value="anio" v-model="filters.anios">
										<label class="custom-control-label" :for="'anio'+index">
											{{anio}}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='tipos'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row">
								<div v-for="(tipo, index) in tipos" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" :id="'anio'+index" :value="tipo.idTipo" v-model="filters.tipos">
										<label class="custom-control-label" :for="'anio'+index">
											{{tipo.descripcion}}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='transmisiones'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="row justify-content-center">
								<div v-for="(transmision, index) in transmisiones" :key="index" class="col-lg-3 mb-3">
									<div class="custom-control custom-switch">
										<input type="checkbox" :id="'transmisiones'+index" class="custom-control-input" :value="transmision.transmision" v-model="filters.transmisiones">
										<label class="custom-control-label" :for="'transmisiones'+index">
											<small>{{transmision.descripcion}}</small>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='precios'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div v-if="filters.precio[0]>= 0 && filters.precio[1]>=0" class="mb-3">
								<p class="text-primary font-weight-bold mb-0">Precio (Q)</p>
								<div class="form-row mb-2">
									<div class="col-6">
										<CurrencyInput v-model="filters.precio[0]" type="text" placeholder="desde" class="form-control border" :min="precio.min" :max="precio.max" />
									</div>
									<div class="col-6">
										<CurrencyInput v-model="filters.precio[1]" type="text" placeholder="hasta" class="form-control border" :min="precio.min" :max="precio.max" />
									</div>
								</div>
								<Slider v-model="filters.precio" :min="precio.min" :max="precio.max" :tooltips="false" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showFilter=='cuota'" class="row justify-content-center align-items-center">
				<div class="col-lg-10 mb-3">
					<div class="card bg-light">
						<div class="card-body">
							<div v-if="filters.cuota[0]>= 0 && filters.cuota[1]>=0" class="mb-3">
								<p class="text-primary font-weight-bold mb-0">Cuota (Q)</p>
								<div class="form-row mb-2">
									<div class="col-6">
										<CurrencyInput v-model="filters.cuota[0]" type="text" placeholder="Desde" class="form-control border" :min="cuota.min" :max="cuota.max" />
									</div>
									<div class="col-6">
										<CurrencyInput v-model="filters.cuota[1]" type="text" placeholder="Hasta" class="form-control border" :min="cuota.min" :max="cuota.max" />
									</div>
								</div>
								<Slider v-model="filters.cuota" :min="cuota.min" :max="cuota.max" :tooltips="false" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="py-5">
			<div v-if="pagination.current_page==1">
				<div class="container-fluid">
					<div class="row justify-content-center">
						<div class="col-lg-8 col-xl-9 mb-3">
							<div class="alert alert-primary alert-dismissible fade show" role="alert" v-if="!isLoggedIn">
								Recuerda para poder solicitar un crédito debes estar <strong><router-link :to="{name:'register'}">registrado</router-link></strong> o <strong><router-link :to="{name:'login'}">iniciar sesion</router-link></strong>
								<button type="button" class="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="form-row justify-content-center">
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[0]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[0].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[0].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[0].ruta, 200)" v-if="vehiculosFilteredPagination[0].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[0].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[0].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[0].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[0].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[0].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[0].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[0].marca}} {{vehiculosFilteredPagination[0].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[0].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[0].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[0].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[1]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[1].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[1].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[1].ruta, 200)" v-if="vehiculosFilteredPagination[1].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[1].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[1].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[1].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[1].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[1].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[1].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[1].marca}} {{vehiculosFilteredPagination[1].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[1].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[1].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[1].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[2]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[2].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[2].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[2].ruta, 200)" v-if="vehiculosFilteredPagination[2].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[2].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[2].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[2].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[2].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[2].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[2].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[2].marca}} {{vehiculosFilteredPagination[2].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[2].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[2].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[2].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[3]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[3].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[3].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[3].ruta, 200)" v-if="vehiculosFilteredPagination[3].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[3].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[3].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[3].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[3].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[3].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[3].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[3].marca}} {{vehiculosFilteredPagination[3].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[3].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[3].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[3].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[4]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[4].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[4].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[4].ruta, 200)" v-if="vehiculosFilteredPagination[4].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[4].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[4].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[4].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[4].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[4].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[4].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[4].marca}} {{vehiculosFilteredPagination[4].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[4].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[4].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[4].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[5]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[5].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[5].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[5].ruta, 200)" v-if="vehiculosFilteredPagination[5].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[5].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[5].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[5].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[5].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[5].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[5].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[5].marca}} {{vehiculosFilteredPagination[5].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[5].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[5].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[5].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="bg-inversion text-center" v-if="false">
					<img src="@/assets/image/banner_marcas.webp" :class="{'img-fluid':isMobile, 'w-100':!isMobile}">
				</div>

				<div class="my-5 bg-inversion text-center py-4" v-if="false">
					<h1 class="display-4 text-white font-weight-bold mb-4">
						Algunas de las marca con las que trabajamos
					</h1>

					<div class="container-fluid">
						<div class="row justify-content-center">
							<div class="col-lg-10">
								<div class="d-flex flex-column flex-lg-row align-items-center justify-content-between">
									<img src="@/assets/image/honda.webp" class="w-auto mb-3" style="height: 100px;">

									<img src="@/assets/image/hyundai.webp" class="w-auto mb-3" style="height: 100px;">

									<img src="@/assets/image/Mazda.webp" class="w-auto mb-3" style="height: 100px;">

									<img src="@/assets/image/Nissan.webp" class="w-auto mb-3" style="height: 100px;">
								</div>
								<div class="d-flex flex-column flex-lg-row align-items-center justify-content-between">
									<div></div>
									<img src="@/assets/image/ford.webp" class="w-auto mb-3" style="height: 100px;">

									<img src="@/assets/image/peugeot.webp" class="w-auto mb-3" style="height: 100px;">

									<img src="@/assets/image/mitsubishi.webp" class="w-auto mb-3" style="height: 100px;">
									<div></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid">
					<div class="row justify-content-center">
						<div class="col-lg-8 col-xl-9 mb-3">
							<div class="form-row justify-content-center" v-if="vehiculosFiltered.length>0">
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[6]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[6].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[6].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[6].ruta, 200)" v-if="vehiculosFilteredPagination[6].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[6].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[6].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[6].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[6].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[6].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[6].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[6].marca}} {{vehiculosFilteredPagination[6].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[6].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[6].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[6].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[7]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[7].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[7].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[7].ruta, 200)" v-if="vehiculosFilteredPagination[7].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[7].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[7].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[7].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[7].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[7].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[7].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[7].marca}} {{vehiculosFilteredPagination[7].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[7].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[7].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[7].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
								<div class="col-lg-4 mb-3" v-if="vehiculosFilteredPagination[8]">
									<div>
										<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculosFilteredPagination[8].idVehiculo}}">
											<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculosFilteredPagination[8].estatus!=1}">
												<img v-lazy="$filters.storage(vehiculosFilteredPagination[8].ruta, 200)" v-if="vehiculosFilteredPagination[8].ruta" class="w-100 h-180 rounded-top" :alt="vehiculosFilteredPagination[8].marca" style="object-fit: cover; height: 160px;">
												<div v-if="vehiculosFilteredPagination[8].idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
													Oferta realizada
												</div>
												<div v-if="vehiculosFilteredPagination[8].estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
													Disponible
												</div>
												<div v-if="vehiculosFilteredPagination[8].estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
													Apartado
												</div>
												<div v-if="vehiculosFilteredPagination[8].estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
													Vendido
												</div>

												<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
													{{$filters.currency(vehiculosFilteredPagination[8].precio)}}
												</div>
											</div>
											<div class="rounded-bottom bg-primary text-white py-2 px-3">
												<div class="text-truncate ">
													<span class="font-weight-bold">{{vehiculosFilteredPagination[8].marca}} {{vehiculosFilteredPagination[8].modelo}}</span>
												</div>
												<div class="d-flex align-items-center justify-content-between">
													<div class="font-small align-items-center d-flex">
														<span class="material-icons align-self-center mr-1">event</span> {{vehiculosFilteredPagination[8].ano}}
													</div>
													<div class="font-small align-items-center d-flex">
														<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculosFilteredPagination[8].numeroPasajeros}}
													</div>
													<div class="font-small align-items-center d-flex justify-content-end">
														{{vehiculosFilteredPagination[8].desTransmision}}
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
							</div>

							<div v-else>
								<div class="text-center py-5" v-if="vehiculos.length>0">
									<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
										<img src="@/assets/image/search_dark.webp" class="img-fluid">
									</div>
									<h5>
										No se encontraron resultados en la búsqueda.
									</h5>
								</div>
								<div :class="{'text-center py-5 my-3':true, 'bg-light rounded':!loadingVehiculos}" v-else>
									<div v-if="loadingVehiculos" class="text-center my-5">
										<PreloaderAutoCompras />
									</div>
									<div v-else>
										<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
											<img src="@/assets/image/search_dark.webp" class="img-fluid">
										</div>
										<h5>
											Aún no hay vehículos disponibles.
										</h5>
										<router-link class="btn btn-primary font-weight-bold" :to="{name:'vehiculos.create'}">
											<span class="material-icons align-bottom">add_a_photo</span>
											Publicar vehículo
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container-fluid" v-else>
				<div class="row justify-content-center">
					<div class="col-lg-8 col-xl-9 mb-3">
						<div class="form-row justify-content-center" v-if="vehiculosFiltered.length>0">
							<div :class="{'mb-3':true, 'col-lg-4':!(pagination.current_page==1 && index == 5), 'col-lg-12':pagination.current_page==1 && index == 5}" v-for="(vehiculo, index) in vehiculosFilteredPagination" :key="index">
								<div>
									<router-link class="card rounded-sm cursor-pointer text-decoration-none" :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}">
										<div :class="{'card-img-top position-relative':true, 'publicacion-deshabilitada':vehiculo.estatus!=1}">
											<img v-lazy="$filters.storage(vehiculo.ruta, 200)" class="w-100 h-180 rounded-top" :alt="vehiculo.marca" style="object-fit: cover; height: 160px;">
											<div v-if="vehiculo.idOferta!=null" class="badge badge-light position-absolute" style="bottom:10px; left: 10px;" title="Haz realizado una oferta por este vehículo">
												Oferta realizada
											</div>
											<div v-if="vehiculo.estatus==1" class="badge badge-inversion position-absolute" style="top:10px; right: 10px;">
												Disponible
											</div>
											<div v-if="vehiculo.estatus==2" class="badge badge-gray position-absolute" style="top:10px; right: 10px;">
												Apartado
											</div>
											<div v-if="vehiculo.estatus==3" class="badge badge-danger position-absolute" style="top:10px; right: 10px;">
												Vendido
											</div>

											<div class="badge badge-light position-absolute" style="bottom: 10px; right: 10px;">
												{{$filters.currency(vehiculo.precio)}}
											</div>
										</div>
										<div class="rounded-bottom bg-primary text-white py-2 px-3">
											<div class="text-truncate ">
												<span class="font-weight-bold">{{vehiculo.marca}} {{vehiculo.modelo}}</span>
											</div>
											<div class="d-flex align-items-center justify-content-between">
												<div class="font-small align-items-center d-flex">
													<span class="material-icons align-self-center mr-1">event</span> {{vehiculo.ano}}
												</div>
												<div class="font-small align-items-center d-flex">
													<span class="material-icons mr-1">airline_seat_recline_normal</span> {{vehiculo.numeroPasajeros}}
												</div>
												<div class="font-small align-items-center d-flex justify-content-end">
													{{vehiculo.desTransmision}}
												</div>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
						<div v-else>
							<div class="text-center py-5" v-if="vehiculos.length>0">
								<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
									<img src="@/assets/image/search_dark.webp" class="img-fluid">
								</div>
								<h5>
									No se encontraron resultados en la búsqueda.
								</h5>
							</div>
							<div :class="{'text-center py-5 my-3':true, 'bg-light rounded':!loadingVehiculos}" v-else>
								<div v-if="loadingVehiculos" class="text-center my-5">
									<PreloaderAutoCompras />
								</div>
								<div v-else>
									<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
										<img src="@/assets/image/search_dark.webp" class="img-fluid">
									</div>
									<h5>
										Aún no hay vehículos disponibles.
									</h5>
									<router-link class="btn btn-primary font-weight-bold" :to="{name:'vehiculos.create'}">
										<span class="material-icons align-bottom">add_a_photo</span>
										Publicar vehículo
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="text-center" v-if="vehiculosFiltered.length>0">
				<Paginacion :pagination="pagination" @paginate="changePagination" :offset="4" class="d-flex justify-content-center" />
			</div>
		</div>

		<div class="bg-white py-5 container-fluid">
			<h3 class="display-4 font-weight-bold text-center mb-3">OTRAS OPCIONES PARA TÍ</h3>
			<PrestamosProductos omit="AutoCompras"></PrestamosProductos>
		</div>

		<div :class="{'bg-credito text-center position-relative':true, 'pb-5':isMobile}">
			<img src="@/assets/image/autocompras-banner-nuevos.webp" :class="{'img-fluid':isMobile, 'w-100':!isMobile}">

			<div class="text-center position-absolute" style="bottom: 10px; left: 0px; right: 0px;">
				<router-link class="btn btn-primary px-2 py-1 font-weight-bold btn-lg" :to="{name:'vehiculos.muro'}">
					Comprar seminuevo
				</router-link>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const Paginacion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pagination" */"@/components/Pagination.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const PrestamosProductos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrestamosProductos" */"@/components/prestamos/Productos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slider = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Slider" */"@vueform/slider"),
		loadingComponent: {template:`<loading-component />`}
	})

	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const PreloaderAutoCompras = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PreloaderAutoCompras" */"@/components/PreloaderAutoCompras.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			Slider,
			CurrencyInput,
			PreloaderAutoCompras,
			Paginacion,
			PrestamosProductos
		},
		data(){
			return {
				loadingVehiculos: false,
				vehiculos: [],
				filters: {
					min: 0,
					max: 0,
					estado: [1,5],
					idTipo: '',
					idColor: '',
					idMarca: '',
					idModelo: '',

					marcas: [],
					modelos: [],
					tipos: [],
					anios: [],
					combustibles: [],
					transmisiones: [],
					origenes: [],
					colors: [],
					precio: [0, 0],
					cuota: [0, 0],
					kilometraje: [0, 100],
				},
				pagination: {
					per_page: 9,
		            current_page: 1,
		            total: 50
		        },
				showFilters: false,
				showFilter: '',
				colors: [],
				tipos: [],
				marcas: [],
				modelos: [],
				anios: [],
				combustibles: [],
				transmisiones: [],
				origenes: [],
				precio: {
					min: 0,
					max: 100
				},

				cuota: {
					min: 0,
					max: 100
				},

				searchModelos: ''
			}
		},
		mounted(){
			//this.getCatalogos()
			this.getVehiculosDisponibles()
		},
		methods:{
			toggleFilter(index){
				if(this.showFilter == index){
					this.showFilter = ''
				}else{
					this.showFilter = index
				}
			},
			changePagination(page){
				this.pagination.current_page = page
				//this.getPublicacionesDisp()
			},

			getVehiculosDisponibles(){
				//if (!this.user.idusuario) {return}
				this.loadingVehiculos = true
				this.$store.dispatch('getVehiculosDisponibles', {
					idUsuarioVehiculo: this.user.idusuario,
					isNew: 1
				}).then(response => {
					this.vehiculos = (response.data || [])
					this.getCatalogos()
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
			clearFilters(){
				this.filters.min = 0
				this.filters.max = 0
				this.filters.estado = [1,5]
				this.filters.idTipo = ''
				this.filters.idColor = ''
				this.filters.idMarca = ''
				this.filters.idModelo = ''

				this.filters.marcas = []
				this.filters.modelos = []
				this.filters.tipos = []
				this.filters.anios = []
				this.filters.combustibles = []
				this.filters.transmisiones = []
				this.filters.origenes = []
				this.filters.colors = []
				
				this.precio.min = 0
				this.precio.max = 0

				this.cuota.min = 0
				this.cuota.max = 0

				this.filters.precio = [0, 0]
				this.filters.cuota = [0, 0]
				this.filters.kilometraje = [0, 100]
				
				this.getCatalogos()
			},
			filtersDesktop(val){
				this.showFilters = val
			},
			amountMax(increment=true){
				if (increment) {
					this.filters.max++
				}else{
					if (this.filters.max<=0) {return}
					this.filters.max--
				}
				//this.loadData()
			},
			amountMin(increment=true){
				if (increment) {
					this.filters.min++
				}else{
					if (this.filters.min<=0) {return}
					this.filters.min--
				}
				//this.loadData()
			},
			getCatalogos(val='') {
				if(val!='modelos'){
					this.modelos = []
				}
				if(val!='marcas'){
					this.marcas = []
				}
				if(val!='colors'){
					this.colors = []
				}
				if(val!='anios'){
					this.anios = []
				}
				if(val!='tipos'){
					this.tipos = []
				}
				if(val!='combustibles'){
					this.combustibles = []
				}
				if(val!='transmisiones'){
					this.transmisiones = []
				}
				if(val!='origenes'){
					this.origenes = []
				}
				if(val!='precio'){
					this.precio.min = 0
					this.precio.max = 0
				}

				if(val!='cuota'){
					this.cuota.min = 0
					this.cuota.max = 0
				}

				for(var i = 0;i<this.vehiculosFiltered.length; i++){
					if(this.modelos.findIndex(x => x.idModelo == this.vehiculosFiltered[i]['idModelo']) == -1){
						this.modelos.push({idModelo: this.vehiculosFiltered[i]['idModelo'] || -1, descripcion: this.vehiculosFiltered[i]['modelo']})
					}
					if(this.marcas.findIndex(x => x.idMarca == this.vehiculosFiltered[i]['idMarca']) == -1){
						this.marcas.push({idMarca: this.vehiculosFiltered[i]['idMarca'] || -1, descripcion: this.vehiculosFiltered[i]['marca']})
					}
					if(this.colors.findIndex(x => x.idColor == this.vehiculosFiltered[i]['idColor']) == -1){
						this.colors.push({idColor: this.vehiculosFiltered[i]['idColor'] || -1, descripcion: this.vehiculosFiltered[i]['color']})
					}
					if(!this.anios.includes(this.vehiculosFiltered[i]['ano'])){
						this.anios.push(this.vehiculosFiltered[i]['ano'])
					}
					if(this.tipos.findIndex(x => x.idTipo == this.vehiculosFiltered[i]['idTipo']) == -1){
						this.tipos.push({idTipo: this.vehiculosFiltered[i]['idTipo'] || -1, descripcion: this.vehiculosFiltered[i]['tipo']})
					}
					if(this.combustibles.findIndex(x => x.tipoCombustible == this.vehiculosFiltered[i]['tipoCombustible']) == -1){
						this.combustibles.push({tipoCombustible: this.vehiculosFiltered[i]['tipoCombustible'] || -1, descripcion: this.vehiculosFiltered[i]['combustible']})
					}
					if(this.transmisiones.findIndex(x => x.transmision == this.vehiculosFiltered[i]['transmision']) == -1){
						this.transmisiones.push({transmision: this.vehiculosFiltered[i]['transmision'], descripcion: this.vehiculosFiltered[i]['desTransmision']})
					}

					if(this.origenes.findIndex(x => x.origen == this.vehiculosFiltered[i]['origen']) == -1){
						this.origenes.push({origen: this.vehiculosFiltered[i]['origen'], descripcion: this.vehiculosFiltered[i]['desOrigen']})
					}
				}
				if(this.precio.max==0){
					var dd = this.vehiculos.map(function(x){
						return x.precio || 0
					})
					this.precio.max = Math.max.apply(null, dd)
					this.filters.precio[1] = Math.max.apply(null, dd)
				}

				if(this.cuota.max==0){
					var ddCuota = this.vehiculos.map(function(x){
						return x.cuota || 0
					})
					this.cuota.max = Math.max.apply(null, ddCuota)
					this.filters.cuota[1] = Math.max.apply(null, ddCuota)
				}


				/*if (this.precio.max==0) {
					if ((this.vehiculosFiltered[i]['precio'] || 0) >= this.precio.max) {
						this.precio.max = this.vehiculosFiltered[i]['precio']
						if(val!='precio'){
							this.filters.precio[1] = this.vehiculosFiltered[i]['precio']
							//this.precioMax = this.vehiculosFiltered[i]['precio']
						}
					}
				}*/
			},
			equals(a, b){
				JSON.stringify(a) === JSON.stringify(b)
			}
		},
		computed:{
			isMobile() {
				return this.$store.getters.isMobile
			},
			aniosFilters(){
				return (this.anios || []).sort().reverse()
			},
			vehiculosFiltered(){
				return this.vehiculos.filter(x => {
					var dd = true
					if (this.filters.marcas.length>0) {
						if (!this.filters.marcas.includes(x.idMarca)) {
							dd = false
						}
					}
					if (this.filters.modelos.length>0) {
						if (!this.filters.modelos.includes(x.idModelo)) {
							dd = false
						}
					}
					if (this.filters.anios.length>0) {
						if (!this.filters.anios.includes(x.ano)) {
							dd = false
						}
					}
					if (this.filters.transmisiones.length>0) {
						if (!this.filters.transmisiones.includes(x.transmision)) {
							dd = false
						}
					}
					if (this.filters.origenes.length>0) {
						if (!this.filters.origenes.includes(x.origen)) {
							dd = false
						}
					}

					if (this.filters.colors.length>0) {
						if (!this.filters.colors.includes(x.origen)) {
							dd = false
						}
					}

					if (this.filters.tipos.length>0) {
						if (!this.filters.tipos.includes(x.idTipo)) {
							dd = false
						}
					}
					if ((this.filters.precio[0] || 0)>=0) {
						if (parseFloat(x.precio || 0) < parseFloat(this.filters.precio[0] || 0)) {
							dd = false
						}
					}
					if ((this.filters.precio[1] || 0)>0) {
						if (parseFloat(x.precio || 0) > parseFloat(this.filters.precio[1] || 0)) {
							dd = false
						}
					}

					if ((this.filters.cuota[0] || 0)>=0) {
						if (parseFloat(x.cuota || 0) < parseFloat(this.filters.cuota[0] || 0)) {
							dd = false
						}
					}
					if ((this.filters.cuota[1] || 0)>0) {
						if (parseFloat(x.cuota || 0) > parseFloat(this.filters.cuota[1] || 0)) {
							dd = false
						}
					}
					return dd
				})
			},
			ini(){
				return ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
			},
			fin(){
				return ((this.pagination.current_page*this.pagination.per_page))
			},
			vehiculosFilteredPagination(){
				return this.vehiculosFiltered.slice(this.ini, this.fin)
			},

			isLoggedIn(){
				return this.$store.getters.isLoggedIn 
			},
			marcasOrder(){
				return (this.marcas || []).sort(function(a,b){ return a.descripcion > b.descripcion ? 1 : 0; })
			},
			marcasFilters(){
				return (this.marcasOrder || [])
			},
			modelosOrder(){
				return (this.modelos || []).sort(function(a,b){ return a.descripcion > b.descripcion ? 1 : 0; })
			},
			modelosFiltered(){
				var data = this.modelosOrder || []
				if (this.searchModelos) {
					return data.filter(x => x.descripcion.includes(this.searchModelos))
				}
				return data
			},
			showData(){
				return this.showFilters && this.vehiculos.length>0
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch: {
			vehiculos: {
				handler(val){
					this.pagination.total = (val.length || 0)
				},
				deep: true
			},
			'vehiculosFiltered': function(val){
				//this.getCatalogos()
				return val
			},
			'filters.modelos': function(val){
				this.getCatalogos('modelos')
				return val
			},
			'filters.marcas': function(val){
				this.getCatalogos('marcas')
				return val
			},
			'filters.colors': function(val){
				this.getCatalogos('colors')
				return val
			},
			'filters.anios': function(val){
				this.getCatalogos('anios')
				return val
			},
			'filters.tipos': function(val){
				this.getCatalogos('tipos')
				return val
			},
			'filters.combustibles': function(val){
				this.getCatalogos('combustibles')
				return val
			},
			'filters.transmisiones': function(val){
				this.getCatalogos('transmisiones')
				return val
			},
			'filters.origenes': function(val){
				this.getCatalogos('origenes')
				return val
			},
			'filters.precio': function(val){
				this.getCatalogos('precio')
				return val
			},
			'filters.cuota': function(val){
				this.getCatalogos('cuota')
				return val
			},
			'filters.max': function(val){
				if (val<0) {
					this.filters.max = 0
				}
				//this.loadData()
			},
			'filters.min': function(val){
				if (val<0) {
					this.filters.min = 0
				}
				//this.loadData()
			},
			'user':{
				handler(val){
					this.getVehiculosDisponibles()
					return val
				},
				deep: true
			}
		}
	}	
</script>