<template>
	<ul class="list-group list-group-flush">
		<template v-for="(vehiculo, index) in vehiculos" :key="index">
			<li class="list-group-item">
				<div class="row align-items-center">
					<div class="col-lg-3 mb-3">
						<img v-lazy="vehiculo.foto1" class="img-fluid" v-if="vehiculo.foto1">
					</div>

					<div class="col-lg-5 mb-3 text-center text-lg-left">
						<div class="font-weight-bold mb-2">
							<template v-if="vehiculo.linea">
								{{vehiculo.linea}}
							</template>
							<template v-else>
								{{vehiculo.marca}} {{vehiculo.modelo}}
							</template>
						</div>

						<div class="mb-2">
							{{$filters.currency(vehiculo.precio)}}
						</div>
						<router-link class="btn btn-outline-inversion" :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}" v-if="id != vehiculo.idVehiculo">
							Ver todo
						</router-link>
					</div>

					<div class="col-lg-4 mb-3">
						<table>
							<tr>
								<td>
									<div class="d-flex align-items-center mb-1">
										<span class="material-icons mr-1">event</span>
										<span class="m-0 text-primary font-weight-bold">Año:</span>
									</div>
								</td>
								<td>
									{{vehiculo.ano}}
								</td>
							</tr>
							<tr>
								<td>
									<div class="d-flex align-items-center mb-1">
										<img src="@/assets/image/transmision.svg" class="mr-1 img-fluid" alt="transmision" style="height: 24px;">
										<span class="m-0 text-primary font-weight-bold">Transmisión:</span>
									</div>
								</td>
								<td>
									{{vehiculo.desTransmision}}
								</td>
							</tr>
							<tr>
								<td>
									<div class="d-flex align-items-center mb-1">
										<span class="material-icons mr-1">local_gas_station</span>
										<span class="m-0 text-primary font-weight-bold">Combustible:</span>
									</div>
								</td>
								<td>
									{{vehiculo.combustible}}
								</td>
							</tr>
							<tr>
								<td>
									<div class="d-flex align-items-center mb-1">
										<span class="material-icons">car_repair</span>
										<span class="m-0 text-primary font-weight-bold">Motor:</span>
									</div>
								</td>
								<td>
									{{vehiculo.motor}}
								</td>
							</tr>
							<tr>
								<td>
									<div class="d-flex align-items-center mb-1">
										<span class="material-icons">car_repair</span>
										<span class="m-0 text-primary font-weight-bold">Agencia:</span>
									</div>
								</td>
								<td>
									{{vehiculo.agencia}}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</li>
		</template>
	</ul>
</template>
<script type="text/javascript">
	export default {
		props: ['modelo'],
		data(){
			return {
				vehiculos: [],
				loadingVehiculos: false
			}
		},

		mounted(){
			this.getVehiculosDisponibles()
		},

		methods: {
			getVehiculosDisponibles(){
				if(!this.modelo){
					this.vehiculos = []
					return
				}
				this.loadingVehiculos = true
				this.$store.dispatch('getVehiculosDisponibles', {
					idModelo: this.modelo,
					isNew: 1
				}).then(response => {
					this.vehiculos = (response.data || [])
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
		},

		computed: {
			id(){
				return this.$route.params.id || -1
			}
		},

		watch: {
			modelo(val){
				this.getVehiculosDisponibles()
				return val
			}
		}
	}
</script>