<template>
    <div class="row justify-content-center">
        <div class="col-md-8 mb-2">
            <form v-on:submit.prevent="login()" ref="userForm">
                <div class="my-3">
                    <img src="@/assets/image/logo_dark.webp" class="img-fluid w-50">
                </div>
                <div class="mb-4">
                    <input id="email" type="text" name="email" v-model="form.mail" class="form-control" placeholder="Usuario" required>
                </div>
                <div class="mb-4">
                    <input id="password" name="password" type="password" class="form-control" v-model="form.password" placeholder="Contraseña" autocomplete="on">
                </div>
                
                <div class="text-center mb-3">
                    <button type="submit" class="btn btn-secondary rounded-pill font-weight-bold px-5 text-primary" :disabled="loadingLogin || !validateForm">
                        <BtnLoading v-if="loadingLogin" />
                        <span v-else>
                            Ingresar
                        </span>
                    </button>
                </div>
                <p class="text-center">
                    o ingresa con
                </p>
                <div class="text-center">
                    <Facebook class="btn btn-link btn-sm" :disabled="loadingLogin" @facebook="loginFacebook" />
                    <Google class="btn btn-link btn-sm" :disabled="loadingLogin" @google="loginGoogle" />
                </div>
            </form>
        </div>
    </div>
</template>
<script type="text/javascript">
	//import gAuth from 'vue3-google-auth'
	import { defineAsyncComponent } from 'vue'
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	var md5 = require('md5')
	import Facebook from '@/components/Facebook.vue'
	import Google from '@/components/Google.vue'
	export default {
        props: [
            'redirect'
        ],

		components: {
			BtnLoading,
			Facebook,
			Google
		},
		data(){
			return {
				loadingLogin: false,
				loadingUser: false,
				validateForm: false,

				form: {
					mail: '',
					password: ''
				}
			}
		},
		methods: {
			loginGoogle(params){
				this.loadingLogin = true
				this.$store.dispatch('login', params).then(response => {
					this.getUser()
					return response
				}).catch(error =>{
					if (error.response) {
						if (error.response.data.msg) {
							if(error.response.data.msg == 'bloqueado'){
								window.toastr.error('Usuario bloqueado', 'Error')
							}else{
								window.toastr.error(error.response.data.msg, 'Error')
							}
						}else{
							window.toastr.error('Usuario no válido', 'Error')
						}
					}else{
						window.toastr.error('Usuario no válido', 'Error')
					}
					return error
				}).then(() => {
					this.loadingLogin = false
				})
			},
			loginFacebook(params){
				this.loadingLogin = true
				this.$store.dispatch('login', params).then(response => {
					this.getUser()
					window.close()
					return response
				}).catch(error =>{
					if (error.response) {
						if (error.response.data.msg) {
							if(error.response.data.msg == 'bloqueado'){
								window.toastr.error('Usuario bloqueado', 'Error')
							}else{
								window.toastr.error(error.response.data.msg, 'Error')
							}
						}else{
							window.toastr.error('Usuario no válido', 'Error')
						}
					}else{
						window.toastr.error('Usuario no válido', 'Error')
					}
					return error
				}).then(() => {
					this.loadingLogin = false
				})
			},
			login(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingLogin = true
				this.$store.dispatch('login',{
					mail: this.form.mail,
					password: this.passwordMD5
				}).then(response => {
					this.getUser()
					return response
				}).catch(error =>{
					if (error.response) {
						if (error.response.data.msg) {
							if(error.response.data.msg == 'bloqueado'){
								window.toastr.error('Usuario bloqueado', 'Error')
							}else{
								window.toastr.error(error.response.data.msg, 'Error')
							}
						}else{
							window.toastr.error('Usuario no válido', 'Error')
						}
					}else{
						window.toastr.error('Usuario no válido', 'Error')
					}
					return error
				}).then(() => {
					this.loadingLogin = false
				})
			},
			getUser(){
				this.loadingUser = true
				this.$store.dispatch('getCurrentUser').then(response => {
					var data = response.data || {}
					window.toastr.info(`Bienvenido ${data.nombre || ''} ${data.apellido || ''}`, 'Hola')
                    if(this.redirect == true){
                        if (data.prf==3) {
						    this.$router.push({name: 'admin.index'})
                        }else{
                            this.$router.push({name: 'perfil.index'})
                        }
                    }
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingUser = false
				})
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
		},
		computed: {
			passwordMD5(){
				if (this.form.password!='') {
					return md5(this.form.password)
				}
				return ''
			},

			params(){
				return this.$route.params || {}
			},
		},

		watch:{
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>