<template>
	<ul class="nav nav-pills nav-justified flex-column flex-lg-row">
		<li class="nav-item" v-for="(vehiculo, index) in vehiculos" :key="index">
			<router-link :class="{'nav-link border border-primary mb-1':true, 'ml-lg-2':index!=0}" :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}" exact-active-class="active">
				<template v-if="vehiculo.linea">
					{{vehiculo.linea}}
				</template>
				<template v-else>
					{{vehiculo.marca}} {{vehiculo.modelo}}
				</template>
			</router-link>
		</li>
	</ul>
</template>
<script type="text/javascript">
	export default {
		props: ['modelo'],
		data(){
			return {
				vehiculos: [],
				loadingVehiculos: false
			}
		},

		mounted(){
			this.getVehiculosDisponibles()
		},

		methods: {
			getVehiculosDisponibles(){
				if(!this.modelo){
					this.vehiculos = []
					return
				}
				this.loadingVehiculos = true
				this.$store.dispatch('getVehiculosDisponibles', {
					idModelo: this.modelo,
					isNew: 1
				}).then(response => {
					this.vehiculos = (response.data || [])
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
		},

		watch: {
			modelo(val){
				this.getVehiculosDisponibles()
				return val
			}
		}
	}
</script>