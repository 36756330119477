<template>
	<div class="modal fade" :id="idDF" tabindex="-1" role="dialog" :aria-labelledby="idDF+'Label'" aria-hidden="true">
	  <div class="modal-dialog modal-lg" role="document">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h5 class="modal-title" :id="idDF+'Label'"></h5>
	        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true">&times;</span>
	        </button>
	      </div>
	      <div class="modal-body">
	      	<iframe class="rounded embed-responsive-item" width="100%" height="350" :src="srcUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	      </div>
	    </div>
	  </div>
	</div>
</template>
<script type="text/javascript">
	export default {
		props: ['id', 'src'],
		data(){
			return {
				open: false
			}
		},
		mounted(){
			var vm = this
			window.$(this.$el).on('show.bs.modal', function () {
				vm.open = true
			})

			window.$(this.$el).on('hidden.bs.modal', function () {
				vm.open = false
			})
		},
		computed: {
			url(){
				return (this.src || '') + '?version=3'
			},
			srcUrl(){
				if(this.open){
					return (this.url || '') + '&autoplay=1'
				}
				return this.url
			},
			idDF(){
				return this.id || 'videoEmbedVehiculo'
			}
		}
	}
</script>