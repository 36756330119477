<template>
    <div class="card overflow-hidden position-relative">
        <div class="card-header bg-secondary border-secondary" style="filter: blur(2px); -webkit-filter: blur(2px);">
            <h3 class="card-title text-center mb-0 font-weight-bold">Publicar vehículo</h3>
        </div>
        <div class="card-body">
            <div class="position-absolute" style="top:0; left:0; right: 0; bottom: 0; background-color: rgba(0,0,0, .5);"></div>
            <div class="text-center py-5">
                <div class="text-center text-white mb-3 position-relative">
                    Debes estar registrado o iniciar sesion para poder publicar tu vehiculo.
                </div>
                <div :class="{'card rounded-lg bg-light mx-auto':true, 'w-75':!isMobile}" v-if="registro">
                    <div class="card-body">
                        <Register @exito="handlerExito" />
                        <br>
                        <div class="d-flex justify-content-between">
                            <div>
                                <small>
                                Si tienes una cuenta,
                                <button v-on:click="registro = !registro" class="font-weight-bold btn btn-link btn-sm">inicia sesión</button>.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{'card rounded-lg bg-light mx-auto':true, 'w-50':!isMobile}" v-else>
                    <div class="card-body">
                        <Auth />
                        <br>
                        <div class="d-flex justify-content-between">
                            <div>
                                <small>
                                Si no tienes una cuenta,
                                <button v-on:click="registro = !registro" class="font-weight-bold btn btn-link btn-sm">Regístrate</button>.
                                </small>
                            </div>
                            <div>
                                <small>
                                    <router-link :to="{name:'reset'}" class="text-secondary">
                                        ¿Olvidaste tu contraseña?
                                    </router-link>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
    import Auth from '@/components/login/Auth.vue'
    import Register from '@/components/login/Register.vue'

    export default {
        components: {
            Auth,
            Register
        },

        data(){
            return{
                registro: false,
                exito: false
            }
            
        },

        methods:{
            handlerExito(){
                this.registro = false
            }
        },

        computed: {
            isMobile() {
                return this.$store.getters.isMobile
            },
        }
    }
</script>