<template>
	<div class="modal fade" id="me-interesa" data-backdrop="static" role="dialog" aria-labelledby="me-interesaLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header bg-primary text-white align-items-center">
					<h3 class="modal-title text-left w-100">¡Me interesa!</h3>
					<button type="button" class="btn btn-link" data-dismiss="modal" aria-label="Close">
						<img src="@/assets/image/close_white.webp" width="20" height="20">
					</button>
				</div>
				<div class="modal-body p-5">
					<div class="alert alert-primary alert-dismissible fade show" role="alert" v-if="!isLoggedIn">
						Recuerda para poder realizar una acción debes estar <strong><router-link :to="{name:'register'}">registrado</router-link></strong> o <strong><router-link :to="{name:'login'}">iniciar sesion</router-link></strong>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="row justify-content-center align-items-stretch">
						<div class="col-md-4 mb-2" v-if="user.prf!=3 && vehiculo.estatus == 1 && vehiculo.apartado===null">
							<div class="card text-center shadow h-100">
								<div class="card-header font-weight-bold bg-light text-credito border-0">
									Aparta el vehículo
								</div>
								<div class="card-body bg-light pt-0">
									<span class="material-icons" style="font-size: 90px;">car_rental</span>
									<p class="mb-0">Realizar una solicitud para apartar el vehículo</p>
								</div>
								<div class="card-footer bg-light border-0">
									<button type="button" class="btn btn-outline-credito rounded-pill font-weight-bold px-3" :disabled="loading || !isLoggedIn" v-on:click="validateApartarVehiculos">
										<BtnLoading v-if="loading" />
										<span v-else>
											Apartar vehículo
										</span>
									</button>
								</div>
							</div>
						</div>
						<div class="col-md-4 mb-2" v-if="user.prf!=3 && (vehiculo.estatus == 1 || vehiculo.estatus == 2)">
							<div class="card text-center shadow h-100">
								<div class="card-header font-weight-bold bg-light text-inversion border-0">
									Oferta por el vehículo
								</div>
								<div class="card-body bg-light pt-0">
									<span class="material-icons" style="font-size: 90px;">directions_car</span>
									<p class="mb-0">Realiza una oferta por el vehículo</p>
								</div>
								<div class="card-footer bg-light border-0">
									<router-link :to="{name:'vehiculos.ofertar', params:{id: id}}" class="btn btn-outline-inversion rounded-pill font-weight-bold px-3 mr-3" v-if="isLoggedIn">
										Realizar oferta
									</router-link>
									<button type="button" class="btn btn-outline-inversion rounded-pill font-weight-bold px-3 mr-3" v-else disabled>
										Realizar oferta
									</button>
								</div>
							</div>
						</div>
						<div class="col-md-4 mb-2">
							<div class="card text-center shadow h-100">
								<div class="card-header font-weight-bold bg-light text-primary border-0">
									Obtener crédito
								</div>
								<div class="card-body bg-light pt-0">
									<div style="height: 100px;" class="d-flex align-items-center justify-content-center">
										<img src="@/assets/image/logo_dark.webp" class="img-fluid w-75">
									</div>
									<p class="mb-0">Aplica a un crédito con <strong>clubcashin.com</strong></p>
								</div>
								<div class="card-footer bg-light border-0">
									<router-link :to="{name:'vehiculos.show', params:{id: id}, query: {tab: 'credito'}}" class="btn btn-outline-primary rounded-pill font-weight-bold px-3 mr-3">
										Obtener crédito
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
		  	</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components:{
			BtnLoading
		},
		data(){
			return {
				loading: false,
			}
		},
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				vm.toIndex()
			})
		},
		methods: {
			toIndex(){
				this.$router.push({name: 'vehiculos.show', params:{id: this.id}})
			},
			validateApartarVehiculos(){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas apartar este vehículo?`,
					buttons: {
						si: function () {
							mod.vehiculoApartarVehiculos()
				    	},
				    	no: function () {

				    	},
				    }
				})
			},
			vehiculoApartarVehiculos(){
				this.loading = true
				this.$store.dispatch('vehiculoApartarVehiculos', {
					idVehiculo: this.id,
					idUsuario: this.user.idusuario
				}).then(response => {
					window.toastr.success("Se ha envíado la solicitud para apartar el vehículo.", "Éxito")
					this.toIndex()
					this.$store.dispatch('getVehiculo',{
						idVehiculo: this.id,
						idUsuarioVehiculo: this.user.idusuario
					})
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loading = false
				})
			},
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			vehiculo(){
				return this.$store.getters.infoVehiculo || {}
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
			window.$('body').removeClass('modal-open').css('padding-right', '')
		}
	}
</script>