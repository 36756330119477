<template>
	<div class="bg-white" style="min-height: calc(100vh - 164px)">
		<div class="container-lg pt-3">
			<SugerenciasLineas :modelo="vehiculo.idModelo" class="justify-content-center" v-if="vehiculo.isNew" />

			<div :class="{'row justify-content-center':true, 'py-3':isMobile, 'py-4':!isMobile}">
				<div class="col-lg-7 mb-3" ref="images">
					<div class="position-relative">
						<div v-if="edit">
							<Upload :class="{'h-100 bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto1" v-on:fileChange="uploadFileVehiculo($event, '0')" style="height: 160px;">
								<img v-lazy="$filters.storageMin(arrayFotos['0'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['0']">
								<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
							</Upload>
						</div>
						<div :class="{'full-image':imgFull}" v-else>
							<a type="button" class="close-image" v-on:click.prevent="fullImageClose()" v-if="imgFull">
								<span class="material-icons">close</span>
							</a>
							<div id="carrouselVehiculosFotos" class="carousel rounded-sm bg-dark-80" data-ride="carousel">
								<ol class="carousel-indicators">
									<li data-target="#carrouselVehiculosFotos" data-slide-to="0" class="active" v-if="vehiculo.foto0"></li>
									<li data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 1 : 0" :class="{'active':!vehiculo.foto0}"></li>
									<li data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 2 : 1" v-if="vehiculo.foto2"></li>
									<li data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 3 : 2" v-if="vehiculo.foto3"></li>
									<li data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 4 : 3" v-if="vehiculo.foto4"></li>
									<li data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 5 : 4" v-if="vehiculo.foto5"></li>
								</ol>
								<div class="carousel-inner rounded-sm">
									<div :class="{'carousel-item active': true, 'vh-100':imgFull}" v-if="vehiculo.foto0">
										<div :class="{'d-flex align-items-center justify-content-center h-100':imgFull}">
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<span class="placeholder col-12" style="height: 400px;"></span>
											</span>
											<template v-else>
												<img v-lazy="$filters.storage(vehiculo.foto0)" :class="{'cursor-pointer':true, 'img-fluid w-100 img-portada':!imgFull, 'img-full object-fit-cover':imgFull}" :alt="vehiculo.marca" v-on:click="fullImage(vehiculo.foto0)" v-if="vehiculo.foto0">
											</template>
										</div>
									</div>

									<div :class="{'carousel-item': true, 'vh-100':imgFull, 'active':!vehiculo.foto0}">
										<div :class="{'d-flex align-items-center justify-content-center h-100':imgFull}">
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<span class="placeholder col-12" style="height: 400px;"></span>
											</span>
											<template v-else>
												<img v-lazy="$filters.storage(vehiculo.foto1)" :class="{'cursor-pointer':true, 'img-fluid w-100 img-portada':!imgFull, 'img-full object-fit-cover':imgFull}" :alt="vehiculo.marca" v-on:click="fullImage(vehiculo.foto1)" v-if="vehiculo.foto1">
											</template>
										</div>
									</div>

									<div :class="{'carousel-item': true, 'vh-100':imgFull}" v-if="vehiculo.foto2">
										<div :class="{'d-flex align-items-center justify-content-center h-100':imgFull}">
											<img v-lazy="$filters.storage(vehiculo.foto2)" :class="{'cursor-pointer':true, 'img-fluid w-100 img-portada':!imgFull, 'img-full object-fit-cover':imgFull}" :alt="vehiculo.marca" v-on:click="fullImage(vehiculo.foto2)">
										</div>
									</div>
									<div :class="{'carousel-item': true, 'vh-100':imgFull}" v-if="vehiculo.foto3">
										<div :class="{'d-flex align-items-center justify-content-center h-100':imgFull}">
											<img v-lazy="$filters.storage(vehiculo.foto3)" :class="{'cursor-pointer':true, 'img-fluid w-100 img-portada':!imgFull, 'img-full object-fit-cover':imgFull}" :alt="vehiculo.marca" v-on:click="fullImage(vehiculo.foto3)">
										</div>
									</div>
									<div :class="{'carousel-item': true, 'vh-100':imgFull}" v-if="vehiculo.foto4">
										<div :class="{'d-flex align-items-center justify-content-center h-100':imgFull}">
											<img v-lazy="$filters.storage(vehiculo.foto4)" :class="{'cursor-pointer':true, 'img-fluid w-100 img-portada':!imgFull, 'img-full object-fit-cover':imgFull}" :alt="vehiculo.marca" v-on:click="fullImage(vehiculo.foto4)">
										</div>
									</div>
									<div :class="{'carousel-item': true, 'vh-100':imgFull}" v-if="vehiculo.foto5">
										<div :class="{'d-flex align-items-center justify-content-center h-100':imgFull}">
											<img v-lazy="$filters.storage(vehiculo.foto5)" :class="{'cursor-pointer':true, 'img-fluid w-100 img-portada':!imgFull, 'img-full object-fit-cover':imgFull}" :alt="vehiculo.marca" v-on:click="fullImage(vehiculo.foto5)">
										</div>
									</div>
								</div>
								<a class="carousel-control-prev" href="#carrouselVehiculosFotos" role="button" data-slide="prev">
									<span class="carousel-control-prev-icon" aria-hidden="true"></span>
									<span class="sr-only">Previous</span>
								</a>
								<a class="carousel-control-next" href="#carrouselVehiculosFotos" role="button" data-slide="next">
									<span class="carousel-control-next-icon" aria-hidden="true"></span>
									<span class="sr-only">Next</span>
								</a>
							</div>
						</div>
						<div v-if="edit" class="position-absolute" style="top:10px; right: 20px; z-index: 10;">
							<select v-model="form.estatus" class="custom-select custom-select-sm border-primary" v-on:click.prevent="">
								<option value="0">Pendiente</option>
								<option value="1">Disponible</option>
								<option value="2">Apartado</option>
								<option value="3">Vendido</option>
								<option value="4">Rechazado</option>
							</select>
						</div>
						<div class="position-absolute" style="top:10px; right: 20px;" v-else>
							<span class="badge badge-light" v-if="vehiculo.estatus==0">
								Pendiente
							</span>
							<span class="badge badge-success" v-if="vehiculo.estatus==1">
								Disponible
							</span>
							<span class="badge badge-secondary" v-if="vehiculo.estatus==2">
								Apartado
							</span>
							<span class="badge badge-danger" v-if="vehiculo.estatus==3">
								Vendido
							</span>
							<span class="badge badge-primary" v-if="vehiculo.estatus==4">
								Rechazado
							</span>
						</div>
					</div>
					<div class="row mt-3 align-items-stretch my-3" v-if="edit">
						<div class="col-3" style="height: 100px;">
							<Upload :class="{'h-100 bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto2" v-on:fileChange="uploadFileVehiculo($event, '1')" style="height: 160px;">
								<img v-lazy="$filters.storageMin(arrayFotos['1'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['1']">
								<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
							</Upload>
						</div>
						<div class="col-3" style="height: 100px;">
							<Upload :class="{'h-100 bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto3" v-on:fileChange="uploadFileVehiculo($event, '2')" style="height: 160px;">
								<img v-lazy="$filters.storageMin(arrayFotos['2'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['2']">
								<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
							</Upload>
						</div>
						<div class="col-3" style="height: 100px;">
							<Upload :class="{'h-100 bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto4" v-on:fileChange="uploadFileVehiculo($event, '3')" style="height: 160px;">
								<img v-lazy="$filters.storageMin(arrayFotos['3'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['3']">
								<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
							</Upload>
						</div>
						<div class="col-3" style="height: 100px;">
							<Upload :class="{'h-100 bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto5" v-on:fileChange="uploadFileVehiculo($event, '4')" style="height: 160px;">
								<img v-lazy="$filters.storageMin(arrayFotos['4'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['4']">
								<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
							</Upload>
						</div>
					</div>
					<div class="d-flex mt-3 align-items-stretch" v-else>
						<div class="cursor-pointer py-2 px-1" data-target="#carrouselVehiculosFotos" data-slide-to="0" v-if="vehiculo.foto0 || loadingPublicacion">
							<span v-if="loadingPublicacion" class="placeholder-glow">
								<span class="placeholder" style="height: 75px; width: 100px; border-radius: 5px;"></span>
							</span>
							<img v-lazy="$filters.storageMin(vehiculo.foto0)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;" v-else>
						</div>
						<div class="cursor-pointer py-2 px-1" data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 1 : 0" v-if="vehiculo.foto1 || loadingPublicacion">
							<span v-if="loadingPublicacion" class="placeholder-glow">
								<span class="placeholder" style="height: 75px; width: 100px; border-radius: 5px;"></span>
							</span>
							<img v-lazy="$filters.storageMin(vehiculo.foto1)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;" v-else>
						</div>
						<div class="cursor-pointer py-2 px-1" data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 2 : 1" v-if="vehiculo.foto2 || loadingPublicacion">
							<span v-if="loadingPublicacion" class="placeholder-glow">
								<span class="placeholder" style="height: 75px; width: 100px; border-radius: 5px;"></span>
							</span>
							<img v-lazy="$filters.storageMin(vehiculo.foto2)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;" v-else>
						</div>
						<div class="cursor-pointer py-2 px-1" data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 3 : 2" v-if="vehiculo.foto3 || loadingPublicacion">
							<span v-if="loadingPublicacion" class="placeholder-glow">
								<span class="placeholder" style="height: 75px; width: 100px; border-radius: 5px;"></span>
							</span>
							<img v-lazy="$filters.storageMin(vehiculo.foto3)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;" v-else>
						</div>
						<div class="cursor-pointer py-2 px-1" data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 4 : 3" v-if="vehiculo.foto4">
							<img v-lazy="$filters.storageMin(vehiculo.foto4)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;">
						</div>
						<div class="cursor-pointer py-2 px-1" data-target="#carrouselVehiculosFotos" :data-slide-to="vehiculo.foto0 ? 5 : 4" v-if="vehiculo.foto5">
							<img v-lazy="$filters.storageMin(vehiculo.foto5)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;">
						</div>
						<div class="cursor-pointer py-2 px-1" v-if="vehiculo.video">
							<div class="position-relative overflow-hidden" style="border-radius: 5px;" data-toggle="modal" data-target="#videoEmbedVehiculo">
								<img v-lazy="$filters.storageMin(vehiculo.foto1)" class="img-fluid h-100" alt="Foto" style="max-height: 75px; object-fit: cover; object-position: center; filter: blur(3px);">
								<div class="position-absolute" style="top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(0,0,0,0.5);">
									<div class=" d-flex align-items-center justify-content-center h-100">
										<span class="material-icons text-white">play_arrow</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-5 px-0 px-md-3 mb-3">
					<div class="card cap-bg border shadow h-100">
						<div class="card-body d-flex flex-column">
							<div :class="{'d-flex justify-content-between':true, 'flex-column':isMobile && edit}">
								<div :class="{'col-5 px-0':loadingPublicacion, 'order-1':isMobile && edit}">
									<div v-if="loadingPublicacion" class="placeholder-glow">
										<h1 class="placeholder col-12 h5"></h1>
									</div>
									
									<div v-else>
										<div v-if="edit" class="form-row justify-content-between w-100">
											<div class="col-lg-4 mb-1">
												<label class="mb-0 font-weight-bold">Marca</label>
												<Marcas v-model="form.idMarca" title="Marca" class="select-sm"/>
											</div>
											<div class="col-lg-4 mb-1">
												<label class="mb-0 font-weight-bold">Modelo</label>
												<Modelos v-model="form.idModelo" title="Modelo" :marca="form.idMarca" :disabled="!form.idMarca" class="select-sm"/>	
											</div>
											<div class="col-lg-4 mb-2" v-if="vehiculo.isNew==1">
												<label for="linea">Línea</label>
												<Lineas id="linea" :modelo="form.idModelo" v-model="form.idLinea" :disabled="!form.idModelo" />
											</div>
										</div>
										<h1 :class="{'mb-3 pl-3 font-weight-bold':true, 'border-left':vehiculo.isNew==0}" :style="{fontSize: '25px', borderWidth: '7px !important', borderColor: color+ ' !important'}" v-else>
											{{vehiculo.marca}} {{vehiculo.modelo}} {{vehiculo.linea}}
										</h1>
									</div>
								</div>
								<div :class="{'d-flex align-items-center':true, 'order-0 justify-content-end':isMobile && edit}">
									<div class="mr-1">
										<div class="dropdown">
											<button class="btn btn-link btn-sm" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Compartir">
												<span class="material-icons text-primary vertical-middle">share</span>
											</button>
											<ul class="dropdown-menu dropdown-menu-right overflow-hidden" aria-labelledby="dropdownMenuButton">
												<li class="dropdown-item" v-for="network in networks" :key="network.network">
													<ShareSocial
														:network="network.network"
														:key="network.network"
														:class="network.class"
														:url="sharing.url"
														:title="sharing.title"
														:description="sharing.description"
														:quote="sharing.quote"
														:hashtags="sharing.hashtags"
														:twitterUser="sharing.twitterUser">
														{{network.nombre}}
													</ShareSocial>
												</li>
												<li class="dropdown-item">
													<a href="#" v-on:click.prevent="print()">
														Imprimir
													</a>
												</li>
												<li class="dropdown-item">
													<a href="#" v-on:click.prevent="copy()">
														Copiar enlace
													</a>
												</li>
											</ul>
										</div>
									</div>

									<router-link :to="{name:'vehiculos.nuevos'}" class="btn btn-link btn-sm" v-if="vehiculo.isNew==1">
										<img src="@/assets/image/close.webp" style="width: 20px; height: 20px;">
									</router-link>
									<router-link :to="{name:'vehiculos.muro'}" class="btn btn-link btn-sm" v-else>
										<img src="@/assets/image/close.webp" style="width: 20px; height: 20px;">
									</router-link>
								</div>
							</div>
							<div v-if="loadingPublicacion" class="placeholder-glow">
								<h3 class="placeholder col-3"></h3>
							</div>
							<div v-else>
								<div v-if="edit" class="d-flex flex-column flex-lg-row align-items-lg-center mt-3 mb-1">
									<label class="mb-0 font-weight-bold mr-2">Precio</label>
									<CurrencyInput type="text" name="precio" class="form-control form-control-sm border" placeholder="Valor" v-model="form.precio" required />
								</div>
								<h3 v-else>
									{{$filters.currency(vehiculo.precio)}}
								</h3>
							</div>
							<div v-if="edit" class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
								<label class="mb-0 font-weight-bold mr-2">Tipo</label>
								<TiposVehiculos v-model="form.idTipo" class="select-sm w-100" />
							</div>
							<div class="mb-1" v-if="vehiculo.tipo && !edit">
								<b>Tipo: </b> 
								<span v-if="loadingPublicacion" class="placeholder-glow">
									<span class="placeholder col-2"></span>
								</span>
								<span v-else>
									{{vehiculo.tipo}}
								</span>
							</div>

							<div v-if="edit" class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
								<label class="mb-0 font-weight-bold mr-2">Color</label>
								<Colores v-model="form.idColor" class="select-sm w-100" />
							</div>

							<div class="mb-1" v-if="vehiculo.color && !edit">
								<b>Color:</b> 
								<span v-if="loadingPublicacion" class="placeholder-glow">
									<span class="placeholder col-2"></span>
								</span>
								<span v-else>
									{{vehiculo.color}}
								</span>
							</div>
							
							<div v-if="edit" class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
								<label class="mb-0 font-weight-bold mr-2 w-100">Número de pasajeros</label>
								<input v-model="form.numeroPasajeros" type="number" min="0" name="numeroPasajeros" class="form-control form-control-sm border" placeholder="Pasajeros" required>
							</div>
							<div class="mb-1" v-if="vehiculo.numeroPasajeros && !edit">
								<b>Número de pasajeros: </b> 
								<span v-if="loadingPublicacion" class="placeholder-glow">
									<span class="placeholder col-2"></span>
								</span>
								<span v-else>
									{{vehiculo.numeroPasajeros}}
								</span>
							</div>

							<div class="mb-1" v-if="vehiculo.isNew == 1 && !edit">
								<b>Agencia:</b> 
								<span v-if="loadingPublicacion" class="placeholder-glow">
									<span class="placeholder col-5"></span>
								</span>
								<span v-else>
									{{vehiculo.agencia}}
								</span>
							</div>
							<div v-if="vehiculo.isNew == 1 && edit" class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
								<label class="mb-0 font-weight-bold mr-2">Agencia</label>
								<input v-model="form.agencia" type="text" name="origen" class="form-control form-control-sm border" placeholder="Agencia" required>
							</div>

							<div class="mb-3" v-if="vehiculo.descripcionVehiculo && !edit">
								<a href="#detalles" v-on:click.prevent="verMas()">Ver más...</a>
							</div>

							<div v-if="vehiculo.isNew==1 && !edit">
								<div class="mb-3">
									<b>Colores disponibles:</b> 
									
									<div class="d-flex flex-wrap">
										<div v-for="(color, index) in coloresFilters" :key="index">
											<div :style="{backgroundColor: color.rgba, width: '25px', height: '25px'}" class="rounded mr-1 border cursor-pointer" :title="color.descripcion" v-on:click="vwImage(color.file)"></div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="edit">
								<div class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
									<label class="mb-0 font-weight-bold mr-2">Año</label>
									<input v-model="form.ano" type="number" name="ano" class="form-control form-control-sm border" minlength="4" maxlength="4" placeholder="Año" min="0" required>
								</div>

								<div class="d-flex flex-column flex-lg-row align-items-lg-center mb-2" v-if="vehiculo.isNew!=1">
									<label class="mb-0 font-weight-bold mr-2">Kilometraje</label>
									<input type="number" v-model="form.kilometraje" name="kilometraje" class="form-control form-control-sm border" maxlength="50" min="0" placeholder="Kilometraje" required>
								</div>

								<div class="d-flex align-items-lg-center mb-2">
									<label class="mb-0 font-weight-bold mr-2">
										Transmisión
									</label>
									<div class="custom-control custom-radio custom-control-inline custom-control-sm">
										<input type="radio" id="transmisionManual" name="transmision" class="custom-control-input" v-model="form.transmision" value="1" required>
										<label class="custom-control-label" for="transmisionManual">Manual</label>
									</div>
									<div class="custom-control custom-radio custom-control-inline custom-control-sm">
										<input type="radio" id="transmisionAutomatico" name="transmision" class="custom-control-input" v-model="form.transmision" value="2" required>
										<label class="custom-control-label" for="transmisionAutomatico">Automático</label>
									</div>
								</div>

								<div class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
									<label class="mb-0 font-weight-bold mr-2">Combustible</label>
									<select class="custom-select custom-select-sm border" v-model="form.tipoCombustible" required>
										<option value="" disabled selected>Combustible</option>
										<option value="1">Diesel</option>
										<option value="2">Electrico</option>
										<option value="3">Gasolina</option>
									</select>
								</div>

								<div class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
									<label class="mb-0 font-weight-bold mr-2">Motor</label>
									<input type="text" name="motor" class="form-control form-control-sm border" maxlength="50" placeholder="Motor" v-model="form.motor" required/>
								</div>

								<div class="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
									<label class="mb-0 font-weight-bold mr-2">Video</label>
									<input type="text" name="video" class="form-control form-control-sm border" maxlength="150" placeholder="Video" v-model="form.video"/>
								</div>

								<div class="d-flex flex-column flex-lg-row align-items-lg-center mt-3 mb-2" v-if="vehiculo.isNew!=1">
									<label class="mb-0 font-weight-bold mr-2">Origen</label>
									<select class="custom-select custom-select-sm border" v-model="form.origen" required>
										<option value="" disabled selected>Origen</option>
										<option value="0">Rodado</option>
										<option value="1">Agencia</option>
									</select>
								</div>
							</div>

							<div class="form-row border rounded mb-3 my-auto" v-else>
								<div :class="{'border-right':!isMobile || vehiculo.isNew!=1, 'col-6 col-lg-4':vehiculo.isNew!=1, 'col-lg-6':vehiculo.isNew==1}">
									<div class="media pt-1">
										<span class="material-icons mr-1">event</span>
										<div class="media-body text-truncate">
											<p class="m-0 text-truncate"><small class="m-0 text-primary font-weight-bold" style="font-size: 76%">Año:</small></p>
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<small class="placeholder col-5"></small>
											</span>
											<small v-else>{{vehiculo.ano}}</small>
										</div>
									</div>
								</div>
								<div :class="{'border-right':!isMobile, 'col-6 col-lg-4':vehiculo.isNew!=1, 'col-lg-6':vehiculo.isNew==1}" v-if="vehiculo.isNew!=1">
									<div class="media pt-1">
										<span class="material-icons mr-1">speed</span>
										<div class="media-body text-truncate">
											<p class="m-0 text-truncate"><small class="m-0 text-primary font-weight-bold" style="font-size: 76%">KM:</small></p>
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<small class="placeholder col-7"></small>
											</span>
											<small v-else>{{$filters.currency(vehiculo.kilometraje, false)}}</small>
										</div>
									</div>
								</div>
								<div :class="{'border-top':isMobile, 'border-right':isMobile && vehiculo.isNew!=1, 'col-6 col-lg-4':vehiculo.isNew!=1, 'col-lg-6':vehiculo.isNew==1}">
									<div class="media pt-1">
										<img src="@/assets/image/transmision.svg" class="mr-1 img-fluid" alt="transmision" style="height: 24px;">
										<div class="media-body text-truncate">
											<p class="m-0 text-truncate"><small class="m-0 text-primary font-weight-bold" style="font-size: 75%;">Transmisión:</small></p>
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<small class="placeholder col-10"></small>
											</span>
											<small v-else>
												{{vehiculo.desTransmision}}
											</small>
										</div>
									</div>
								</div>
								<div :class="{'border-top':true, 'border-right':!isMobile, 'col-6 col-lg-4':vehiculo.isNew!=1, 'col-lg-6':vehiculo.isNew==1}">
									<div class="media pt-1">
										<span class="material-icons mr-1">local_gas_station</span>
										<div class="media-body text-truncate">
											<p class="m-0 text-truncate"><small class="m-0 text-primary font-weight-bold" style="font-size: 75%;">Combustible:</small></p>
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<small class="placeholder col-10"></small>
											</span>
											<small v-else>
												{{vehiculo.combustible}}
											</small>
										</div>
									</div>
								</div>
								<div :class="{'border-top':true, 'col-6 col-lg-4 border-right':vehiculo.isNew!=1, 'col-lg-6':vehiculo.isNew==1}">
									<div class="media pt-1">
										<span class="material-icons">car_repair</span>
										<div class="media-body text-truncate">
											<p class="m-0 text-truncate"><small class="m-0 text-primary font-weight-bold" style="font-size: 75%;">Motor:</small></p>
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<small class="placeholder col-6"></small>
											</span>
											<small v-else>
												{{vehiculo.motor}}
											</small>
										</div>
									</div>
								</div>
								<div class="col-6 col-lg-4 border-top" v-if="vehiculo.isNew!=1">
									<div class="media pt-1">
										<span class="material-icons mr-1">emoji_transportation</span>
										<div class="media-body text-truncate">
											<p class="m-0 text-truncate"><small class="m-0 text-primary font-weight-bold" style="font-size: 75%;">Origen:</small></p>
											<span v-if="loadingPublicacion" class="placeholder-glow">
												<small class="placeholder col-7"></small>
											</span>
											<small v-else>{{vehiculo.desOrigen}}</small>
										</div>
									</div>
								</div>
							</div>

							<br>
							<div class="d-flex justify-content-between align-items-center mt-2">
								<div class="mb-3 text-right" v-if="isLoggedIn && vehiculo.idUsuario == user.idusuario">
									<button type="button" v-if="vehiculo.estatus<=1 && !edit" class="btn btn-danger rounded-pill font-weight-bold px-3 mr-3" v-on:click="destroy()" :disabled="loading">
										<BtnLoading v-if="loadingEliminar" />
										<span v-else>
											Eliminar
										</span>
									</button>
								</div>
								<div class="mb-3" v-if="vehiculo.idUsuario != user.idusuario && !edit">
									<router-link :to="{name:'vehiculos.contactar', params: {id: id}}" class="btn btn-warning" v-if="vehiculo.isNew == 0">
										<div class="d-flex align-items-center">
											<span class="material-icons mr-3">email</span>
											Contactar anunciante
										</div>
									</router-link>
								</div>

								<button type="button" v-on:click="editClick()" class="btn btn-outline-info rounded-pill font-weight-bold mb-3" :disabled="loading" v-if="!edit && user.prf==3">
									<BtnLoading v-if="loadingEdit" />
									<span v-else>
										<span class="material-icons align-bottom">edit</span>
										Editar
									</span>
								</button>

							</div>

							<div v-if="isLoggedIn">
								<div class="text-right" v-if="vehiculo.idUsuario != user.idusuario || user.prf==3">
									<div v-if="user.prf!=3" class="row">
										<div class="col-lg-6 mb-3" v-if="vehiculo.isNew!=1">
											<router-link :to="{name:'vehiculos.ofertar', params:{id: id}}" class="btn btn-outline-inversion btn-block rounded-pill font-weight-bold px-3 mr-3" v-if="(vehiculo.estatus == 1 || vehiculo.estatus == 2) && vehiculo.idOferta==null">
												Realizar oferta
											</router-link>
											<div class="text-left">
												<small class="text-muted">* Compra de contado</small>
											</div>
										</div>

										<div class="col-lg-6 mb-3">
											<button type="button" class="btn btn-outline-primary btn-block rounded-pill font-weight-bold px-3 mr-3" v-if="(vehiculo.estatus == 1 || vehiculo.estatus == 2) && vehiculo.idOferta==null" v-on:click="goCalculadora()">
												Cómpralo en cuotas
											</button>
										</div>
									</div>
									<div v-else class="text-center">
										<button type="button" v-on:click="update()" class="btn btn-info rounded-pill w-50 font-weight-bold" :disabled="loading" v-if="edit">
											<BtnLoading v-if="loadingEdit" />
											<span v-else>
												<span class="material-icons align-bottom">save</span>
												Actualizar
											</span>
										</button>

										<button type="button" v-on:click="editClick(false)" class="btn btn-link rounded-pill w-50 font-weight-bold" :disabled="loading" v-if="edit">
											<BtnLoading v-if="loadingEdit" />
											<span v-else>
												Cancelar
											</span>
										</button>
									</div>
									<div v-if="vehiculo.idOferta!=null">
										<div class="text-left alert alert-primary alert-dismissible fade show" role="alert">
											Pronto recibirás la información para completar tu compra
											<button type="button" class="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true">&times;</span>
											</button>
										</div>
									</div>
									<div v-if="vehiculo.estatus == 0 && !vehiculo.fechaInspeccion && user.prf==3" class="card cap-bg border">
										<div class="card-body text-center">
											<div>
												<button type="button" v-if="vehiculo.estatus == 0" v-on:click="validateUpdate(4)" class="btn btn-danger rounded-pill font-weight-bold mr-3" :disabled="loading">
													<BtnLoading v-if="loadingRechazar" />
													<span v-else>
														<span class="material-icons align-bottom">close</span>
														Rechazar
													</span>
												</button>
												<button type="button" v-if="vehiculo.estatus == 0" v-on:click="validateUpdate(1)" class="btn btn-success rounded-pill font-weight-bold mr-3" :disabled="loading">
													<BtnLoading v-if="loadingAutorizar" />
													<span v-else>
														<span class="material-icons align-bottom">check</span>
														Autorizar
													</span>
												</button>
											</div>

											<br>
											o
											<p class="text-center">Debes asignar una fecha de inspección</p>
											<input type="date" class="form-control mb-3" placeholder="Fecha de inspección" v-model="fechaInspeccion">
											<button type="button" class="btn btn-primary" v-on:click="updateVehiculo" :disabled="loading">
												<BtnLoading v-if="loadingUpdate" />
												<span v-else>
													Asignar fecha
												</span>
											</button>
											<router-link :to="{name:'admin.agenda'}" class="btn btn-link">
												Ir a agenda
											</router-link>
										</div>
									</div>
								</div>
							</div>
							<div class="alert alert-primary alert-dismissible fade show" role="alert" v-else>
								Recuerda para poder ofertar o apartar debes estar <strong><router-link :to="{name:'register'}">registrado</router-link></strong> o <strong><router-link :to="{name:'login'}">iniciar sesion</router-link></strong>
								<button type="button" class="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-12 mb-3" v-if="edit">
					<label>Seleccionar colores</label>
					<div class="form-row">
						<div class="col-lg-2 mb-2 text-center" v-for="(color, index) in form.colores" :value="color.idColor" :key="index">
							<div>
								<Upload :class="{'px-3 py-1 bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" :name="'fotoColor'+index" v-on:fileChange="vehiculosUploadFileColor($event, color)">
									<div class="w-100">
										<div :class="{'mb-2':true, 'deshabilitado':!color.check}">
											<img v-lazy="$filters.storageMin(color.file)" class="img-fluid" :alt="'Agregar foto del color '+color.descripcion" :title="'Agregar foto del color '+color.descripcion" style="height: 80px" v-if="color.file">
											<Car :style="{color: color.rgba, height: '80px'}" class="img-fluid border-svg" v-else />
										</div>

										<div class="custom-control custom-checkbox" v-on:click.stop="''">
											<input type="checkbox" class="custom-control-input" :id="'color'+index" v-model="color.check" @change="updateColorVehiculoCheck(color)">
											<label class="custom-control-label" :for="'color'+index">
												<div class="font-weight-bold text-primary">
													{{color.descripcion}}
												</div>
											</label>
										</div>
									</div>
								</Upload>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-7 mb-5" id="detalles" v-if="vehiculo.descripcionVehiculo || (user.prf == 3 && edit)">
					<h2 class="font-weight-bold ml-4">Detalles</h2>
					<div class="card cap-bg border">
						<div class="card-body">
							<div v-if="edit">
								<textarea v-model="form.descripcionVehiculo" name="desc" placeholder="Descripción" class="form-control border mb-3" maxlength="1000" minlength="10" rows="5" required></textarea>
								<div class="text-right">
									<button type="button" v-on:click="update()" class="btn btn-info rounded-pill font-weight-bold" :disabled="loading">
										<BtnLoading v-if="loadingEdit" />
										<span v-else>
											<span class="material-icons align-bottom">save</span>
											Actualizar
										</span>
									</button>
								</div>
							</div>
							<div class="text-justify white-space" v-else>
								<span v-if="loadingPublicacion" class="placeholder-glow">
									<span class="placeholder col-12"></span>
									<span class="placeholder col-7"></span>
									<span class="placeholder col-10"></span>
									<span class="placeholder col-5"></span>
									<span class="placeholder col-12"></span>
								</span>
								<span v-else>
									{{vehiculo.descripcionVehiculo}}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-5 mb-5" id="cotizacion" v-if="(vehiculo.estatus == 1 || vehiculo.estatus == 2) && (user.idusuario != vehiculo.idUsuario || user.prf==3)">
					<div class="mb-5">
						<h2 class="text-center font-weight-bold">Calcula tu cuota:</h2>
						<p>
							Aquí puedes hacerte una idea de la cuota que podrías llegar a pagar
						</p>
					</div>

					<div class="px-3">
						<div>
							<div class="mb-3">
								<div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
									<label>Plazo (meses)</label>
									<div class="mb-1">
										<input type="number" v-model="cotizacion.plazo" class="form-control border" :max="maxPlazo" step="1">
									</div>
									<p class="font-weight-bold" v-if="false">
										{{cotizacion.plazo}} meses
									</p>
								</div>
								<Slider v-model="cotizacion.plazo" :step="1" :max="maxPlazo" :tooltips="false" />
							</div>
							<div v-if="vehiculo.precio>0" class="mb-3">
								<div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
									<label>Enganche ({{enganchePorc}}%)</label>
									<div class="mb-1">
										<CurrencyInput v-model="engancheInput" type="text" :max="vehiculo.precio" :min="engancheMin" class="form-control border" />
									</div>
									<p class="font-weight-bold" v-if="false">
										{{$filters.currency(cotizacion.enganche)}}
									</p>
								</div>
								<Slider v-model="cotizacion.enganche" :min="engancheMin" :max="vehiculo.precio" :tooltips="false" />
								<span v-if="message" class="text-danger">
									{{message}}
								</span>
							</div>
						</div>
						<div>
							<div class="d-flex align-items-center justify-content-between mb-3">
								<h5>Monto a solicitar:</h5>
								{{$filters.currency(credito)}}
							</div>

							<div class="card bg-light mb-3">
								<div class="card-body">
									<div v-if="false">
										<div class="d-flex align-items-center justify-content-between mb-3" v-if="showMore">
											<span>Cuota fija</span>
											<strong>{{$filters.currency(cuota)}}</strong>
										</div>

										<div class="d-flex align-items-center justify-content-between mb-3" v-if="showMore">
											<span>Costo del seguro</span>
											<strong>{{$filters.currency(cotizacion.seguro)}}</strong>
										</div>

										<div class="d-flex align-items-center justify-content-between mb-3 border-bottom" v-if="showMore">
											<span>Costo del GPS</span>
											<strong>{{$filters.currency(cotizacion.gps)}}</strong>
										</div>
									</div>

									<div class="d-flex align-items-center justify-content-between mb-3">
										<span>Cuota mensual</span>
										<strong>{{$filters.currency(total)}}</strong>
									</div>

									<div class="text-muted font-weight-bold">
										<small class="font-weight-bold">*Tu cuota incluye seguro y GPS</small>
									</div>
								</div>
							</div>

							<div class="col-lg-12 text-center">
								<router-link :to="{name:'precalifique.auto-compras.index', query: paramsQuery}" :class="{'btn btn-primary mb-3':true, 'disabled':message}" v-if="false">
									Me interesa
								</router-link>
								<div v-if="(vehiculo.estatus == 1 || vehiculo.estatus == 2) && vehiculo.idOferta==null && isLoggedIn">
									<button type="button" class="btn btn-primary mb-3" :disabled="loading" v-on:click="vehiculoOfertaCredito">
										<BtnLoading v-if="loadingCredito" />
										<span v-else>
											Me interesa
										</span>
									</button>
								</div>
								<div>
									<small class="text-muted">* Cuota puede variar según tu análisis de crédito</small>
								</div>

								<div class="alert alert-primary alert-dismissible fade show" role="alert" v-if="!isLoggedIn">
									Recuerda para poder obtener financiamiento debes estar <strong><router-link :to="{name:'register'}">registrado</router-link></strong> o <strong><router-link :to="{name:'login'}">iniciar sesion</router-link></strong>
									<button type="button" class="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div v-if="vehiculo.idOferta!=null">
									<div class="text-left alert alert-primary alert-dismissible fade show" role="alert">
										Pronto recibirás la información para completar tu compra
										<button type="button" class="close" data-dismiss="alert" aria-label="Close">
										<span aria-hidden="true">&times;</span>
										</button>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

				<div v-if="user.prf == 3" class="col-lg-12 mb-3">
					<div class="card mb-3 w-100 bg-light">
						<div class="card-header">
							<h4 class="card-title mb-0">Datos del usuario:</h4>
						</div>
						<div class="card-body">
							<div class="row align-items-center justify-content-between">
								<div class="col-lg-6 mb-3">
									<strong>Nombres:</strong> {{vehiculo.nombreUsuario}} {{vehiculo.apellidoUsuario}}
								</div>

								<div class="col-lg-6 mb-3" v-if="vehiculo.dpiUsuario">
									<strong>DPI:</strong> {{vehiculo.dpiUsuario}}
								</div>

								<div class="col-lg-6 mb-3" v-if="vehiculo.mailUsuario">
									<strong>Correo electrónico:</strong> <a :href="'mailto:'+vehiculo.mailUsuario">{{vehiculo.mailUsuario}}</a>
								</div>

								<div class="col-lg-6 mb-3" v-if="vehiculo.telefonoUsuario">
									<strong>Teléfono:</strong> <a :href="'tel:'+vehiculo.telefonoUsuario">{{vehiculo.telefonoUsuario}}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-10 mb-5" id="inspeccion" v-if="vehiculo.fechaInspeccion">
					<Inspeccion :datos="vehiculo" :disabled="!(vehiculo.estatus==0 && user.prf==3)" v-if="user.prf == 3 || vehiculo.inspeccion>0" />
					<div v-if="false">
						<Inspeccion :datos="vehiculo" :disabled="user.prf!=3" v-if="user.prf == 3 || vehiculo.inspeccion>0" />
					</div>
				</div>
				<router-view></router-view>
			</div>

			<div v-if="vehiculo.isNew">
				<h2 class="text-center">
					Líneas disponibles
				</h2>
				<SugerenciasLineasList :modelo="vehiculo.idModelo" />
			</div>
		</div>

		<ModalVideo id="videoEmbedVehiculo" :src="vehiculo.video"></ModalVideo>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	import SugerenciasLineas from "@/views/vehiculos/Sugerencias.vue"
	import SugerenciasLineasList from "@/views/vehiculos/SugerenciasLineasList.vue"
	import ModalVideo from "@/components/vehiculos/Videos.vue"
	
	import Inspeccion from '@/components/vehiculos/Inspeccion.vue'
	import Car from '@/components/svg/Car.vue'

	const Colores = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosColores" */"@/components/catalogos/Colores.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const TiposVehiculos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosTiposVehiculos" */"@/components/catalogos/TiposVehiculos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Modelos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosModelos" */"@/components/catalogos/Modelos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const ShareSocial = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "ShareSocial" */"@/components/ShareSocial.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Slider = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Slider" */"@vueform/slider"),
		loadingComponent: {template:`<loading-component />`}
	})

	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Lineas = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosLineas" */"@/components/catalogos/Lineas.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Marcas = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosMarcas" */"@/components/catalogos/Marcas.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const datosSeguros = require('@/assets/datos.json')
	
	export default {
		components: {
			Slider,
			BtnLoading,
			ShareSocial,
			Inspeccion,
			CurrencyInput,
			Marcas,
			Modelos,
			TiposVehiculos,
			Colores,
			Upload,
			Car,
			Lineas,
			SugerenciasLineas,
			SugerenciasLineasList,
			ModalVideo
		},
		data(){
			return {
				showMore: true,
				loadingPublicacion: false,
				loadingEliminar: false,
				loadingCredito: false,
				loadingUpdate: false,
				cotizacion: {
					plazo: 0,
					enganche: 0,
					gps: 0,
					seguro: 0
				},
				message: '',
				engancheInput: 0,
				seguros: datosSeguros.seguros || [],
				fechaInspeccion: '',
				loadingRechazar: false,
				loadingAutorizar: false,
				imgFull: '',
				edit: false,
				loadingEdit: false,
				loadingColores: false,
				form: {
					estatus: '',
					idMarca: '',
					idModelo: '',
					idTipo: '',
					idColor: '',
					numeroPasajeros: '',
					anio: '',
					kilometraje: '',
					transmision: '',
					combustible: '',
					motor: '',
					video: '',
					origen: 1,
					colores: [],
				},
				arrayFotos: [],
				loadingFile: false
			}
		},
		mounted(){
			this.getGPS()
			this.getVehiculo()
			this.cotizacion.enganche = this.engancheMin || 0
		},
		created(){
			this.cotizacion.plazo = this.maxPlazo
		},
		methods: {
			print(){
				window.print();
			},
			copy(){
				navigator.clipboard.writeText(window.location.href);
				window.toastr.info('Se ha copiado correctamente al portapapeles.')
			},
			uploadFileVehiculo(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				formData.append('images', file)
		        formData.append('modulo', 'vehiculos')
				this.$store.dispatch('uploadFile', formData).then(response => {
					this.arrayFotos[tipo] = 'https://clubcashin.com/storage/vehiculos/'+response.data[0]
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},
			editClick(val=true){
				this.edit = val

				if (this.edit) {
					this.form.estatus = this.vehiculo.estatus
					this.form.idMarca = this.vehiculo.idMarca
					this.form.idModelo = this.vehiculo.idModelo
					this.form.precio = this.vehiculo.precio
					this.form.idTipo = this.vehiculo.idTipo
					this.form.idColor = this.vehiculo.idColor
					this.form.numeroPasajeros = this.vehiculo.numeroPasajeros
					this.form.descripcionVehiculo = this.vehiculo.descripcionVehiculo
					this.form.ano = this.vehiculo.ano
					this.form.kilometraje = this.vehiculo.kilometraje
					this.form.transmision = this.vehiculo.transmision
					this.form.tipoCombustible = this.vehiculo.tipoCombustible
					this.form.motor = this.vehiculo.motor
					this.form.video = this.vehiculo.video
					this.form.origen = this.vehiculo.origen

					this.arrayFotos['0'] = this.vehiculo.foto1
					this.arrayFotos['1'] = this.vehiculo.foto2
					this.arrayFotos['2'] = this.vehiculo.foto3
					this.arrayFotos['3'] = this.vehiculo.foto4
					this.arrayFotos['4'] = this.vehiculo.foto5
				}else{
					this.form.estatus = ''
					this.form.idMarca = ''
					this.form.idModelo = ''
					this.form.precio = ''
					this.form.idTipo = ''
					this.form.idColor = ''
					this.form.numeroPasajeros = ''
					this.form.descripcionVehiculo = ''
					this.form.ano = ''
					this.form.kilometraje = ''
					this.form.transmision = ''
					this.form.tipoCombustible = ''
					this.form.motor = ''
					this.form.video = ''
					this.form.origen = ''
					this.arrayFotos = []
				}
			},

			update(){
				this.loadingEdit = true
				this.form.idVehiculo = this.id
				this.form.arrayFotos = this.arrayFotosDF || []
				this.$store.dispatch('vehiculoUpdate', this.form).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					this.editClick(false)
					this.getGPS()
					this.getVehiculo()
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingEdit = false
				})
			},

			fullImage(val){
				this.imgFull = val
			},

			vwImage(val){
				if(val){
					this.vehiculo.foto0 = val
				}else{
					this.vehiculo.foto0 = ''
				}
				this.scrollToElement('images')
				$('.carousel').carousel(0)
			},

			fullImageClose(){
				this.imgFull = ''
			},
			verMas(){
				document.getElementById('detalles').scrollIntoView({behavior: "smooth"})
			},
			goCalculadora(){
				document.getElementById('cotizacion').scrollIntoView({behavior: "smooth"})
			},
			getVehiculo(){
				this.loadingPublicacion = true
				this.$store.dispatch('getVehiculo',{
					idVehiculo: this.id,
					idUsuarioVehiculo: this.user.idusuario,
					isNew: -1
				}).then(response => {
					if(this.vehiculo.isNew){
						this.getColoresByVehiculo()
					}

					this.getSeguro()
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			getColoresByVehiculo(){
				this.loadingColores = true
				this.$store.dispatch('getColoresByVehiculo',{
					idVehiculo: this.id
				}).then(response => {
					this.form.colores = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingColores = false
				})
			},

			vehiculosUploadFileColor(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				formData.append('images', file)
		        formData.append('modulo', 'vehiculos-colores')
				this.$store.dispatch('uploadFile', formData).then(response => {
					var fileUp = response.data[0]

					tipo.file = fileUp
					tipo.check = true
					this.updateColorVehiculo(tipo)

					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},

			updateColorVehiculoCheck(color){
				//color.check = type
				this.updateColorVehiculo(color)
			},

			updateColorVehiculo(color){
				var form = color || {}
				form.idVehiculo = this.id
				this.loadingColores = true
				this.$store.dispatch('updateColorVehiculo', form).then(response => {
					this.getColoresByVehiculo()
					window.toastr.info('Actualizado')
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingColores = false
				})
			},

			destroy(){
				if (this.vehiculo.idUsuario != this.user.idusuario) {
					window.toastr.error('No tienes autorizado realizar esta acción.', 'Error')
					return
				}
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas eliminar este vehículo?`,
					buttons: {
						eliminar: function () {
							mod.vehiculoUpdateStatus(5)
				    	},
				    	cancelar: function () {

				    	},
				    }
				})
			},
			validateUpdate(estado){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas ${estado == 1 ? 'autorizar' : 'rechazar'} este vehículo?`,
					buttons: {
						si: function () {
							if(estado == 1){
								mod.vehiculoUpdateStatusAutorizar(estado)
							}else{
								mod.vehiculoUpdateStatusRechazar(estado)
							}
				    	},
				    	no: function () {

				    	},
				    }
				})
			},
			getInteres(){
				this.loadingUpdate = true
				this.$store.dispatch('getInteres', {
					idVehiculo: this.vehiculo.idVehiculo,
				}).then(response => {
					//window.toastr.success("Cambio realizado con éxito.", "Éxito")
					//this.getVehiculo()
					return response
				}).catch(error =>{
					//window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingUpdate = false
				})
			},
			vehiculoUpdateStatusAutorizar(estado){
				if (estado==1) {
					this.loadingAutorizar = true
				}
				if (estado==4) {
					this.loadingRechazar = true
				}
				this.$store.dispatch('vehiculoUpdateStatus', {
					idVehiculo: this.vehiculo.idVehiculo,
					estatus: estado
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					if (estado==1) {
						this.getInteres()
					}
					this.$router.push({name: 'admin.vehiculos.pendientes'})
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					if (estado==1) {
						this.loadingAutorizar = false
					}
					if (estado==4) {
						this.loadingRechazar = false
					}
				})
			},
			vehiculoUpdateStatusRechazar(estado){
				this.loadingRechazar = true
				this.$store.dispatch('vehiculoUpdateStatus', {
					idVehiculo: this.id,
					estatus: estado
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					this.$router.push({name: 'admin.vehiculos.pendientes'})
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingRechazar = false
				})
			},
			updateVehiculo(){
				this.loadingUpdate = true
				this.$store.dispatch('updateVehiculo', {
					idVehiculo: this.id,
					fechaInspeccion: this.fechaInspeccion
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					this.getVehiculo()
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingUpdate = false
				})
			},
			vehiculoUpdateStatus(estado){
				this.loadingEliminar = true
				this.$store.dispatch('vehiculoUpdateStatus', {
					idVehiculo: this.id,
					estatus: estado
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					this.$router.back()
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingEliminar = false
				})
			},
			/*validateObtenerCredito(){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas solicitar el crédito para este vehículo?`,
					buttons: {
						si: function () {
							mod.vehiculoOfertaCredito()
				    	},
				    	no: function () {

				    	},
				    }
				})
			},*/
			vehiculoOfertaCredito(){
				this.loadingCredito = true
				this.$store.dispatch('vehiculoOfertaCredito', {
					idVehiculo: this.id,
					idUsuario: this.user.idusuario,
					monto: this.vehiculo.precio,
					plazo: this.cotizacion.plazo,
					enganche: this.cotizacion.enganche,
					cuota: this.cuota,
					seguro: this.cotizacion.seguro,
					gps: this.cotizacion.gps,
					total: this.total
				}).then(response => {
					//window.toastr.success("Solicitud envíada.", "Éxito")
					//this.getVehiculo()
					//{name:'precalifique.auto-compras.index', query: paramsQuery}

					if(this.vehiculo.isNew==1){
						this.$router.push({name:'precalifique.auto-compras-nuevos.index', query: this.paramsQuery})
					}else{
						this.$router.push({name:'precalifique.auto-compras.index', query: this.paramsQuery})
					}
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar la gestión, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingCredito = false
				})
			},
			getSeguro(){
				var dd = this.seguros.filter(x => parseFloat((x.monto || '0').replace(/[,]/g, '')) <= parseFloat(this.vehiculo.precio || 0))
				var first = dd[dd.length-1] || {}

				var seguroMonto = 0
				if(this.vehiculo.isNew){
					seguroMonto = first.seguro_nuevo || 0
				}else{
					seguroMonto = first.seguro_particular || 0
				}
				
				this.cotizacion.seguro = parseFloat((seguroMonto || '0').replace(/[,]/g, ''))
			},
			getGPS(){
				this.cotizacion.gps = parseFloat((datosSeguros.gps || '0').replace(/[,]/g, ''))
			}
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},

			maxPlazo(){
				return this.vehiculo.isNew ? 84 : 60
			},

			vehiculo(){
				return this.$store.getters.infoVehiculo || {}
			},
			coloresFilters(){
				return this.form.colores.filter(x => x.idVehiculo)
			},
			enganchePorc(){
				return (((this.cotizacion.enganche) / (this.vehiculo.precio || 1)*100)).toFixed(2)
			},
			interes(){
				return parseFloat((datosSeguros.interes['auto-compras'] || '0').replace(/[,]/g, '')) * 1.12
			},
			loading(){
				return this.$store.getters.loading
			},
			credito(){
				return ((this.vehiculo.precio || 0)-(this.cotizacion.enganche || 0))
			},
			cuota(){
				var d = ((this.credito || 0) * (this.interes || 0))
				var e = (1-Math.pow((1+this.interes), ((this.cotizacion.plazo || 0)*-1)))
				return d/e
			},
			fotos(){
				return this.$store.getters.publicacionFotos || []
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			isMobile(){
				return this.$store.getters.isMobile
			},
			user(){
				return this.$store.getters.currentUser || {}
			},

			total(){
				return (this.cotizacion.seguro || 0) + (this.cotizacion.gps || 0) + (this.cuota || 0)
			},
			sharing(){
				return {
					url: window.location.href || '',
					title: (this.vehiculo.marca || '') + ' ' + (this.vehiculo.modelo || ''),
					description: this.vehiculo.descripcionVehiculo || '',
					quote: '',
					hashtags: 'clubcashin,autoCompras',
					twitterUser: ''
				}
			},
			networks(){
				return [
					{network:'facebook', nombre: 'Facebook', class:'', style: '', icon: [ 'fab',  'facebook-f']},
					{network:'whatsapp', nombre: 'Whatsapp', class:'', style: '', icon: [ 'fab',  'whatsapp']},
					{network:'twitter', nombre: 'Twitter', class:'', style: '', icon: [ 'fab',  'twitter']},
					{network:'sms', nombre: 'SMS', class:'', icon: 'sms'},
					{network:'email', nombre: 'Email', class:'', icon: 'envelope-open-text'}
				]
			},
			color(){
				return this.vehiculo.rgba || 'rgb(0, 0, 0)'
			},
			forms(){
				return {
					idusuario: this.user.idusuario,
					idprenda: this.id,
					fecha: Math.round(new Date().getTime()/1000.0)
				}
			},
			paramsQuery(){
				return {
					plazo: this.cotizacion.plazo, 
					enganche: this.cotizacion.enganche, 
					monto: this.vehiculo.precio,
					marca: this.vehiculo.marca,
					modelo: this.vehiculo.modelo,
					anio: this.vehiculo.ano,
					tipo: this.vehiculo.desOrigen,
					sc: 'formulario'
				}
			},
			engancheMin(){
				return parseFloat(((this.vehiculo.precio || 0) * (10/100.00)).toFixed(2))
			},
			enganche(){
				return this.cotizacion.enganche
			},
			arrayFotosDF(){
				var fotos = this.arrayFotos || []
				if (fotos.constructor === Array) {
					return fotos.filter(x => x)
				}
				return []
			},
		},
		watch:{
			'user':{
				handler(val){
					this.getVehiculo()
					return val
				},
				deep: true
			},
			engancheMin(val){
				if (val>this.cotizacion.enganche) {
					this.cotizacion.enganche = val
				}
			},
			enganche(val){
				this.engancheInput = val
			},
			engancheInput(val){
				if (val>=this.engancheMin && val<=this.vehiculo.precio) {
					this.cotizacion.enganche = val || 0
					this.message = ''
				}else{
					if (val>this.vehiculo.precio) {
						this.message = 'El valor es mayor al monto del crédito'
					}else{
						this.message = 'El valor es menor al enganche mínimo'
					}
				}
			},
			/*credito(val){
				if (val>0) {
					this.getSeguro(val)
					this.getGPS()
				}else{
					this.cotizacion.seguro = 0
					this.cotizacion.gps = 0
				}
			}*/
			maxPlazo(val){
				this.cotizacion.plazo = val
			},

			id(val){
				this.getGPS()
				this.getVehiculo()
				this.cotizacion.enganche = this.engancheMin || 0
				this.cotizacion.plazo = this.maxPlazo
				this.vehiculo.foto0 = ''
				this.edit = false
				$('.carousel').carousel(0)
				return val
			}
		}
	}
</script>
