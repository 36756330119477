<template>
    <div class="container-fluid bg-white">
		<div class="row justify-content-center align-items-center">
			<div class="col-lg-8 mb-5 order-1 order-lg-0">
				<h1 class="text-center mt-5 font-weight-bold display-4">¡VENDE TU CARRO!</h1>
				<div class="row justify-content-center">
					<div class="col-lg-8">
						<p class="h2 mt-5" style="font-weight: lighter;">Utiliza el marketplace de AutoCompras ¡Gratis! y si tu cliente necesita finaciamiento nosotros se lo damos con las mejores condiciones.</p>
					</div>
				</div>
				
				<div class="text-center mt-5">
					<button type="button" v-on:click="scrollToElement('scrollToMe', 'focusInput')" class="btn btn-secondary rounded-pill font-weight-bold px-5">
						Solicítalo
					</button>
				</div>
			</div>
			<div :class="{'col-lg-4 mb-5 p-0 order-0 order-lg-1':true, 'bg-secondary text-center':isMobile, 'text-right':!isMobile}">
				<img src="@/assets/image/vende-carro-autocompras.webp" class="img-fluid" alt="Préstamo" style="margin-right: ">
			</div>
			<div class="col-lg-10 mb-4 order-2">
				
			</div>
		</div>
	</div>
	<div class="container-fluid bg-white" id="publicar" ref="scrollToMe">
		<div class="row justify-content-center h-100" style="min-height: calc(100vh - 164px);">
			<div class="col-lg-10 py-5">
				<div class="card overflow-hidden" v-if="isLoggedIn">
					<div class="card-header bg-secondary border-secondary">
						<h3 class="card-title text-center mb-0 font-weight-bold">Publicar vehículo</h3>
					</div>
					<div class="card-body bg-light">
						<form ref="userForm" v-on:submit.prevent="addVehiculo()" class="needs-validation was-validated" novalidate>
							<div class="row px-md-5">
								<div class="col-lg-5 align-items-center">
									<div class="form-row">
										<div class="col-lg-6 mb-2">
											<select class="custom-select" v-model="vehiculo.idMarca" required>
												<option value="" disabled selected>Marca</option>
												<option v-for="(marca, index) in marcas" :value="marca.idMarca" :key="index">{{marca.descripcion}}</option>
											</select>
										</div>
										<div class="col-lg-6 mb-2">
											<select class="custom-select" v-model="vehiculo.idModelo" required :disabled="!vehiculo.idMarca">
												<option value="" disabled selected>Modelo</option>
												<option v-for="(modelo, index) in modelosFiltered" :value="modelo.idModelo" :key="index">{{modelo.descripcion}}</option>
											</select>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0 mr-2">
												<small>Transmisión</small>
											</label>
											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="transmisionManual" name="transmision" class="custom-control-input" v-model="vehiculo.transmision" value="1" required>
												<label class="custom-control-label" for="transmisionManual">Manual</label>
											</div>
											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="transmisionAutomatico" name="transmision" class="custom-control-input" v-model="vehiculo.transmision" value="2" required>
												<label class="custom-control-label" for="transmisionAutomatico">Automático</label>
											</div>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Año</label>
											<input v-model="vehiculo.ano" type="number" name="ano" class="form-control" minlength="4" maxlength="4" placeholder="Año" min="0" ref="focusInput" required>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Kilometraje</label>
											<input type="number" v-model="vehiculo.kilometraje" name="kilometraje" class="form-control" maxlength="50" min="0" placeholder="Kilometraje" required>
										</div>

										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Pasajeros</label>
											<input v-model="vehiculo.numeroPasajeros" type="number" min="0" name="numeroPasajeros" class="form-control" placeholder="Pasajeros" required>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Tipo</label>
											<select class="custom-select" v-model="vehiculo.idTipo" required>
												<option value="" disabled selected>Tipo</option>
												<option v-for="(tipo, index) in tipos" :value="tipo.idTipo" :key="index">{{tipo.descripcion}}</option>
											</select>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Color</label>
											<select class="custom-select" v-model="vehiculo.idColor" required>
												<option value="" disabled selected>Color</option>
												<option v-for="(color, index) in colors" :value="color.idColor" :key="index">
													{{color.descripcion}}
												</option>
											</select>
										</div>
										<div class="col-lg-12 mb-2" v-if="false">
											<!-- Example single danger button -->
											<div class="btn-group btn-block">
												<button type="button" class="btn btn-outline-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
													Color
												</button>
												<div class="dropdown-menu p-3">
													<div class="form-row">
														<div class="col-3 mb-2 text-center" v-for="(color, index) in colors" :value="color.idColor" :key="index">
															<div class="p-1 cap-bg rounded-sm">
																<div :style="{backgroundColor: color.rgba, width: '25px', height: '25px'}" class="rounded mx-auto"></div>
																<small>{{color.descripcion}}</small>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Combustible</label>
											<select class="custom-select" v-model="vehiculo.tipoCombustible" required>
												<option value="" disabled selected>Combustible</option>
												<option value="1">Diesel</option>
												<option value="2">Electrico</option>
												<option value="3">Gasolina</option>
											</select>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Origen</label>
											<select class="custom-select" v-model="vehiculo.origen" required>
												<option value="" disabled selected>Origen</option>
												<option value="0">Rodado</option>
												<option value="1">Agencia</option>
											</select>
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0">Valor</label>
											<CurrencyInput type="text" name="precio" class="form-control" placeholder="Valor" v-model="vehiculo.precio" required />
										</div>
										<div class="col-lg-12 mb-2">
											<label class="mb-0" v-if="false">Motor</label>
											<input type="text" name="motor" class="form-control" maxlength="50" placeholder="Motor" v-model="vehiculo.motor" required/>
										</div>
									</div>
								</div>
								<div class="col-lg-7">
									<div class="col-lg-12 mb-5">
										<textarea v-model="vehiculo.descripcionVehiculo" name="desc" placeholder="Descripción" class="form-control" maxlength="1000" minlength="10" rows="5" required></textarea>
									</div>
									<div class="row align-items-center mb-3">
										<div class="col-lg-4">
											<label>Agregar tus fotos</label>
										</div>
										<div class="col-lg-8">
											<div class="form-row">
												<div class="col-lg-6 mb-3">
													<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto1" v-on:fileChange="uploadFileVehiculo($event, '0')" style="height: 160px;">
														<img v-lazy="$filters.storageMin(arrayFotos['0'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['0']">
														<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
													</Upload>
												</div>
												<div class="col-lg-6 mb-3">
													<div class="form-row">
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto2" v-on:fileChange="uploadFileVehiculo($event, '1')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['1'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['1']">
																<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto3" v-on:fileChange="uploadFileVehiculo($event, '2')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['2'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['2']">
																<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto4" v-on:fileChange="uploadFileVehiculo($event, '3')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['3'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['3']">
																<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto5" v-on:fileChange="uploadFileVehiculo($event, '4')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['4'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['4']">
																<img src="@/assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
													</div>
												</div>
												<div style="color:rgb(221,44,0);">
													{{msjErrorFoto}}
												</div>
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center">
										<div>
											Al publicar una solicitud, estás de acuerdo y aceptas los términos y condiciones.
										</div>

										<div class="text-right px-2">
											<button type="submit" class="btn btn-inversion" :disabled="loadingPublicacion || !validateForm">
												<BtnLoading v-if="loadingPublicacion" />
												<span v-else>
													Publicar
												</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<Login  v-else/>
			</div>
			<div class="col-lg-10 py-5">
				<h1 class="text-center font-weight-bold mb-5 display-4">Te compartimos más información</h1>
				<div class="row align-items-center justify-content-center pb-5">
					<div class="col-lg-6">
						<div class="accordion" id="accordionExample">
							<div class="" id="headingQueEs">
								<h2 class="mb-0">
									<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0 mb-3" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#queEs" aria-expanded="true" aria-controls="queEs">
										<span class="h3 text-primary">¿Por qué es mi mejor opción?</span>
										<img src="@/assets/image/down-arrow.webp" alt="">
									</button>
								</h2>
							</div>

							<div id="queEs" class="collapse" aria-labelledby="headingQueEs" data-parent="#accordionExample">
								<div class="card-body">
									<div class="pr-md-5">
										<p>Puedes publicar tu carro para venderlo en nuestra plataforma completamente gratis. Si tu cliente necesita financiamiento, nosotros se lo damos. A tí no te cobramos ninguna comisión.</p>
									</div>
								</div>
							</div>
							<div class="" id="headingFunciona"  v-if="false">
								<h2 class="mb-0">
									<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0 mb-3" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#funciona" aria-expanded="true" aria-controls="funciona">
										<span class="h3 text-primary">¿Clubcashin.com compra carros?</span>
										<img src="@/assets/image/down-arrow.webp" alt="">
									</button>
								</h2>
							</div>

							<div id="funciona" class="collapse" aria-labelledby="headingFunciona" data-parent="#accordionExample">
								<div class="card-body">
									<div class="pr-md-5">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent arcu velit, iaculis a luctus ut, vehicula sed nulla. In hac habitasse platea dictumst. Integer aliquam volutpat justo, et dapibus orci malesuada vitae. Nam rutrum id dolor et fermentum. Nunc posuere est vel maximus facilisis. Ut mattis est ut scelerisque convallis. Sed ut tortor a augue varius bibendum vitae ac lacus.</p>
									</div>
								</div>
							</div>
							<div class="" id="headingClubCashin">
								<h2 class="mb-0">
									<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0 mb-3" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#clubCashin" aria-expanded="true" aria-controls="clubCashin">
										<span class="h3 text-primary">¿Qué es Clubcashin.com?</span>
										<img src="@/assets/image/down-arrow.webp" alt="">
									</button>
								</h2>
							</div>

							<div id="clubCashin" class="collapse" aria-labelledby="headingClubCashin" data-parent="#accordionExample">
								<div class="card-body">
									<div class="pr-md-5">
										<p>
											Somos la 1era Fintech p2p Lending de Guatemala, hemos otorgado millones de quetzales en préstamos a través de cientos de inversionistas. Impactamos en la vida de los guatemaltecos apoyándolos a cumplir sus sueños.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-6 text-center">
						<video class="rounded img-fluid" src="https://clubcashin.com/img/CashIn_Usuario.mp4" controls v-if="false">
							Tu navegador no soporta estos videos
						</video>
						<div class="embed-responsive embed-responsive-16by9">
							<iframe  class="rounded embed-responsive-item" src="https://www.youtube.com/embed/Xq4EsdtnyBo?version=3&loop=1&playlist=Xq4EsdtnyBo&controls=0&rel=0" title="Vende tu carro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	import Login from '@/components/login/Index.vue'
	
	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		components: {
			BtnLoading,
			CurrencyInput,
			Upload,
			Login
		},
		data(){
			return {
				validateForm: false,
				loadingPublicacion: false,
				vehiculo: {
					ano: '',
					idUsuario: '',
					predio: '',
					kilometraje: '',
					transmision: '',
					idTipo: '',
					idColor: '',
					idMarca: '',
					idModelo: '',
					tipoCombustible: '',
					numeroPasajeros: '',
					descripcionVehiculo: '',
					arrayFotos: [],
					precio: '',
					origen: '',
					motor: ''
				},
				colors: [],
				tipos: [],
				marcas: [],
				modelos: [],

				msjErrorFoto: '',
			}
		},
		mounted(){
			this.getCatalogos()
		},
		methods:{
			getCatalogos(){
				this.$store.dispatch('vehiculosGetTipos').then(res=>{
					this.tipos = res.data || []
				}).catch()
				this.$store.dispatch('vehiculosGetColores').then(res=>{
					this.colors = res.data || []
				}).catch()
				this.$store.dispatch('vehiculosGetMarcas').then(res=>{
					this.marcas = res.data || []
				}).catch()
				this.$store.dispatch('vehiculosGetModelos').then(res=>{
					this.modelos = res.data || []
				}).catch()
			},
			addVehiculo(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				if (this.arrayFotos.length<=0) {
					this.msjErrorFoto = 'Debes agregar al menos una foto.'
					window.toastr.error(this.msjErrorFoto, 'Error')
					return
				}
				this.loadingPublicacion = true
				this.vehiculo.idUsuario = this.user.idusuario
				this.vehiculo.predio = this.user.predio
				this.$store.dispatch('addVehiculos', this.vehiculo).then(response => {
					window.toastr.info('Vehículo envíado exitosamente para aprobación.', 'Aviso')
					if(this.user.predio == null){
						this.$router.push({name: 'perfil.misVehiculos.pendientes'})
					}else{
						this.$router.push({name: 'admin.vehiculos.aprobadas'})
					}
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar usuario', 'Error')
					}
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			uploadFileVehiculo(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				formData.append('images', file)
				formData.append('modulo', 'vehiculos')
				this.$store.dispatch('uploadFile', formData).then(response => {
					this.vehiculo.arrayFotos[tipo] = response.data[0]
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			}
		},
		computed:{
			user(){
				return this.$store.getters.currentUser || []
			},
			arrayFotos(){
				return (this.vehiculo.arrayFotos || []).map(x => {
					if((window.url.substr(window.url.length - 1) == '/' || x.charAt(0) == '/')){
						return window.url + x
					}

					return window.url + '/' + x
				})
			},

			modelosFiltered(){
				if (!this.vehiculo.idMarca) {
					return []
				}
				return this.modelos.filter(x => x.idMarca == this.vehiculo.idMarca)
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
			isLoggedIn() {
				return this.$store.getters.isLoggedIn
			},
		},
		watch:{
			'vehiculo':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>